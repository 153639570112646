import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-index-landing-two',
  templateUrl: './index-landing-two.component.html',
  styleUrls: ['./index-landing-two.component.css']
})
export class IndexLandingTwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


}
