 <!-- Footer Start -->
 <footer class="footer">
   <div class="container">
     <div class="row">
       <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
         <a routerLink="/" class="logo-footer">
           <img src="assets/images/logo-dark-mdsis-2.png" height="60" alt="">
         </a>
         <p class="mt-4">
           En MDSIS nos enfocamos en mantener la satisfacción de nuestros clientes, 
           con soluciones tecnológicas que potencien sus negocios, garantizando la 
           mayor calidad posible y el respaldo de nuestra experiencia y trayectoria 
           en el mercado.
          </p>
         <!--end icon-->
       </div>
       <!--end col-->

       <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
         <h4 class="text-light footer-head">Empresa</h4>
         <ul class="list-unstyled footer-list mt-4">
           <li><a routerLink="/aboutus" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> ¿Quiénes Somos?</a>
           </li>
           <li><a routerLink="/contact" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> Contáctenos</a>
           </li>
         </ul>
       </div>
       <!--end col-->

       <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
         <h4 class="text-light footer-head">Servicios</h4>
         <ul class="list-unstyled footer-list mt-4">
           <li><a routerLink="/sofware-factory" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> Fábrica de Software</a>
           </li>
           <li><a routerLink="/automation" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> Automatización</a>
           </li>
           <li><a routerLink="/business-intelligence" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> Inteligencia de Negocios</a>
           </li>
           <li><a routerLink="/it-consulting" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> Consultoría en TI</a>
           </li>
           <!--
           <li><a routerLink="/project-management" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> Gestión de Proyectos</a>
           </li>
           <li><a routerLink="/talent-management" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> Gestión de Talentos</a>
           </li>
            -->
           <li><a routerLink="/digital-transformation" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> Transformación Digital</a>
           </li>
         </ul>
       </div>
       <!--end col-->

       <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
         <h4 class="text-light footer-head">Boletín Informativo</h4>
         <p class="mt-4">
           Mantengase al tanto de información, noticias y ofertas especiales.
         </p>
         <form novalidate [formGroup]="SubscribeForm" (ngSubmit)="onFormSubmit()" ngNoForm>
           <div class="row">
             <div class="col-lg-12">
               <div class="foot-subscribe form-group position-relative">
                 <label>Escriba su email <span class="text-danger">*</span></label>
                 <i data-feather="mail" class="fea icon-sm icons"></i>
                 <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                 <input type="email" name="email" id="emailsubscribe" class="form-control pl-5 rounded" formControlName="emailsubscribe"
                   placeholder="Your email : " required>
               </div>
             </div>
             <div class="col-lg-12">
               <input type="submit" id="submitsubscribe" name="send" class="btn btn-soft-primary btn-block"
                 value="Enviar">
             </div>

              <!-- Modal Content Start -->
              <ng-template #contentSubscribeConfirmation let-modal>
                <div class="modal-header">
                  <h5 class="modal-title" id="modal-basic-title">Suscripción Realizada!</h5>
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="bg-white p-3 rounded box-shadow">
                    <p class="text-muted mb-0">
                      Gracias por ponerse en contacto con nosotros.<br><br>
                      A partir de ahora, recibirá notificaciones de interés 
                      con información de noticias, servicios y eventos disponibles 
                      para su organización.
                    </p>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Aceptar</button>
                </div>
              </ng-template>
              <!-- Modal Content End -->

           </div>
         </form>
       </div>
       <!--end col-->
     </div>
     <!--end row-->
   </div>
   <!--end container-->
 </footer>
 <!--end footer-->
 <!-- Footer End -->
