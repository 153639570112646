import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forums-topic',
  templateUrl: './forums-topic.component.html',
  styleUrls: ['./forums-topic.component.css']
})
export class ForumsTopicComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }



}
