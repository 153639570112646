<!-- Navbar STart -->
<header id="topnav" class="defaultscroll sticky" (window:scroll)="windowScroll()" [class]="navClass">
  <div class="container">
    <!-- Logo container-->
    <div>
      <a class="logo" routerLink="/" *ngIf="navClass !== 'nav-light'">
        <img src="assets/images/logo-dark-mdsis-2.png" class="l-dark" height="40" alt="">
        <img src="assets/images/logo-dark-mdsis-2.png" class="l-light" height="40" alt="">
      </a>
      <a class="logo" routerLink="/" *ngIf="navClass === 'nav-light'">
        <img src="assets/images/logo-dark-mdsis-2.png" class="l-dark" height="40" alt="">
        <img src="assets/images/logo-dark-mdsis-2.png" class="l-light" height="40" alt="">
      </a>
    </div>

    <!--end login button-->
    <!-- End Logo container-->
    <div class="menu-extras">
      <div class="menu-item">
        <!-- Mobile menu toggle-->
        <a class="navbar-toggle" (click)="toggleMenu()" [ngClass]="{'open': isCondensed === true}">
          <div class="lines">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
        <!-- End mobile menu toggle-->
      </div>
    </div>

    <div id="navigation">
      <!-- Navigation Menu-->
      <ul class="navigation-menu" [class]="navClass">
        <li>
          <a routerLink="/" class="nav-link-ref">Inicio</a>
        </li>
        <li class="has-submenu">
          <a href="javascript:void(0)" (click)="onMenuClick($event)">Servicios</a><span class="menu-arrow"></span>
          <ul class="submenu megamenu">
            <li>
              <ul>
                <li><a class="nav-link-ref" routerLink="/sofware-factory">Fábrica de Software</a></li>
                <li><a class="nav-link-ref" routerLink="/automation">Automatización</a></li>
                <li><a class="nav-link-ref" routerLink="/business-intelligence">Inteligencia de Negocio</a></li>
                <li><a class="nav-link-ref" routerLink="/it-consulting">Consultoría en TI</a></li>
                <!--
                <li><a class="nav-link-ref" routerLink="/project-management">Gestión de Proyectos</a></li>
                <li><a class="nav-link-ref" routerLink="/talent-management">Gestión de Talentos</a></li>
                -->
                <li><a class="nav-link-ref" routerLink="/digital-transformation">Transformación Digital</a></li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <a class="nav-link-ref" routerLink="/aboutus">Nuestra Empresa</a>
        </li>
        <li>
          <a class="nav-link-ref" routerLink="/contact">Contáctenos</a>
        </li>
      </ul>
    </div>
    <!--end navigation-->
  </div>
  <!--end container-->
</header>
<!--end header-->
<!-- Navbar End -->