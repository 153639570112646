import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-index-studio',
  templateUrl: './index-studio.component.html',
  styleUrls: ['./index-studio.component.css']
})
export class IndexStudioComponent implements OnInit {
  navClass = 'bg-white';

  constructor() { }

  ngOnInit(): void {
  }


}
