<!-- Hero Start -->
<section class="bg-half d-table w-100" id="home" style="background: url('assets/images/2.jpg') center center;">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title text-white title-dark"> All Cases </h4>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                <li class="breadcrumb-item"><a href="#">Pages</a></li>
                <li class="breadcrumb-item active" aria-current="page">Cases</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->


<section class="section">
  <div class="container">
    <div class="row">
      <ul class="col container-filter list-unstyled categories-filter text-center mb-0" id="filter">
        <li class="list-inline-item"><a class="categories border d-block text-dark rounded active"
            (click)="activeCategory('all')" [ngClass]="{'active': galleryFilter ==='all'}" data-filter="*">All</a></li>
        <li class="list-inline-item"><a class="categories border d-block text-dark rounded"
            (click)="activeCategory('business')" [ngClass]="{'active': galleryFilter ==='business'}"
            data-filter=".business">Business</a></li>
        <li class="list-inline-item"><a class="categories border d-block text-dark rounded"
            (click)="activeCategory('marketing')" [ngClass]="{'active': galleryFilter ==='marketing'}"
            data-filter=".marketing">Marketing</a></li>
        <li class="list-inline-item"><a class="categories border d-block text-dark rounded"
            (click)="activeCategory('finance')" [ngClass]="{'active': galleryFilter ==='finance'}"
            data-filter=".finance">Finance</a></li>
        <li class="list-inline-item"><a class="categories border d-block text-dark rounded" data-filter=".human"
            (click)="activeCategory('human')" [ngClass]="{'active': galleryFilter ==='human'}">Human
            Research</a></li>
      </ul>
    </div>
    <!--end row-->

    <div class="row projects-wrapper">
      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 business" *ngFor="let item of filterredImages">
        <div class="card blog border-0 work-container work-classic shadow rounded-md overflow-hidden">
          <img src="{{item.image}}" class="img-fluid work-image" alt="">
          <div class="card-body">
            <div class="content">
              <a href="javascript:void(0)" class="badge badge-primary">{{item.type}}</a>
              <h5 class="mt-3"><a routerLink="/page-case-detail" class="text-dark title">200% Extended Facebook
                  Reach</a></h5>
              <p class="text-muted">This is required when, for example, the final text is not yet available.</p>
              <a href="javascript:void(0)" class="text-primary h6">Read More
                <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top"
  [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
