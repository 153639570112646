<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" style="background: url('assets/images/Fondos/fd2.jpg') center center;" id="home">
  <div class="bg-overlay Not"></div>
  <div class="container">
    <div class="row align-items-center mt-md-5">
      
      <div class="col-lg-4 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0 order-2 order-md-1">
      </div>
      <!--end col-->

      <div class="col-lg-7 offset-lg-1 col-md-6 order-1 order-md-2 bg-overlay-local">
        <div class="title-heading mt-4">
          <h1 class="heading title-dark text-white mb-3">Soluciones innovadoras para su empresa </h1>
          <p class="para-desc para-dark text-light">
            Hacemos crecer su organización con soluciones modernas, evolutivas y fácilmente adaptables,
            capaces de afrontar los retos de un mundo cada vez más cambiante y mercados orientados hacia entornos digitales. 
          </p>
        
          <div class="watch-video mt-4 pt-2">
            <a routerLink="/aboutus" class="btn btn-primary mb-2 mr-2">Conózcanos</a>
            
            <!--
            <a (click)="openWindowCustomClass(content)" href="javascript:void(0);"
              class="video-play-icon watch title-dark text-light mb-2"><i
                class="mdi mdi-play play-icon-circle text-center d-inline-block mr-2 rounded-circle text-white title-dark position-relative play play-iconbar"></i>
              Ver Video</a>
            <ng-template #content let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <iframe src="https://player.vimeo.com/video/99025203" height="450" width="780" frameborder="0"
                  allowfullscreen allow="autoplay; encrypted-media"></iframe>
              </div>
            </ng-template>
            -->
          </div>
        

        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->


<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Servicios Empresariales</h4>
          <p class="text-muted para-desc mb-0 mx-auto">
            Contrate nuestros servicios y dispondrá de herramientas empresariales robustas, 
            pensadas en potenciar la operatividad de su negocio, disminuyendo costos y optimizando los flujos de trabajo de sus colaboradores.
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-chart-line"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Fábrica de Software</h5>
            <p class="para text-muted mb-0">Herramientas diseñadas o integradas para satisfacer las necesidades de su negocio.</p>
          </div>
          <span class="big-icon text-center">
            <i class="uil uil-chart-line"></i>
          </span>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-crosshairs"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Automatización</h5>
            <p class="para text-muted mb-0">Reducción de tiempo y costos por cargas de trabajo, con procesos de negocio automatizados.</p>
          </div>
          <span class="big-icon text-center">
            <i class="uil uil-crosshairs"></i>
          </span>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-airplay"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Análisis de Datos</h5>
            <p class="para text-muted mb-0">Gestión de datos maestros, inteligencia de negocios, inteligencia artificial y BigData.</p>
          </div>
          <span class="big-icon text-center">
            <i class="uil uil-airplay"></i>
          </span>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-search"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Consultoría en TI</h5>
            <p class="para text-muted mb-0">Toda la información que requiera en tecnología, con ayuda de nuestros especialistas.</p>
          </div>
          <span class="big-icon text-center">
            <i class="uil uil-search"></i>
          </span>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-file-alt"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Gestión de Proyectos</h5>
            <p class="para text-muted mb-0">Líderes certificados en gestión de proyectos, y metodologías ágiles o tradicionales.</p>
          </div>
          <span class="big-icon text-center">
            <i class="uil uil-file-alt"></i>
          </span>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-users-alt"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Gestión de Talentos</h5>
            <p class="para text-muted mb-0">Personal idóneo, calificado y comprometido con el crecimiento de su organización.</p>
          </div>
          <span class="big-icon text-center">
            <i class="uil uil-users-alt"></i>
          </span>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-rocket"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Transformación Digital</h5>
            <p class="para text-muted mb-0">Especialistas en definición e implementación de procesos de transformación digital exitosos.</p>
          </div>
          <span class="big-icon text-center">
            <i class="uil uil-rocket"></i>
          </span>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-clock"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Suporte 24/7</h5>
            <p class="para text-muted mb-0">Personal dispuesto a atender sus requerimientos las 24 horas, durante todo el año.</p>
          </div>
          <span class="big-icon text-center">
            <i class="uil uil-clock"></i>
          </span>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>

<!-- Feature Start -->
<section class="section pt-0 pb-0">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Enfoque en la Satisfacción</h4>
          <p class="text-muted para-desc mb-0 mx-auto">
            En <span class="text-primary font-weight-bold">MDSIS,</span> 
            nuestro equipo de trabajo 
            está centrado principalmente en superar las expectativas de nuestros clientes, 
            ofreciendo soluciones con calidad y respaldo. Contamos con líderes especialistas en 
            distintas áreas de trabajo, que acompañan el proceso desde el OnBoarding hasta la entrega final, 
            para garantizar el mejor producto técnico y funcional.
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-md-4 col-12 mt-5">
        <div class="features text-center">
          <div class="image position-relative d-inline-block">
            <img src="assets/images/icon/pen.svg" class="avatar avatar-small" alt="">
          </div>

          <div class="content mt-4">
            <h4 class="title-2">Orientado al Negocio</h4>
            <p class="text-muted mb-0">Pensamiento enfocado hacia las necesidades, crecimiento y adaptación del negocio.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 col-12 mt-5">
        <div class="features text-center">
          <div class="image position-relative d-inline-block">
            <img src="assets/images/icon/video.svg" class="avatar avatar-small" alt="">
          </div>

          <div class="content mt-4">
            <h4 class="title-2">Optimización y Rendimiento</h4>
            <p class="text-muted mb-0">Propiciamos la mejora continua en cada proceso, necesidad o función del negocio.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 col-12 mt-5">
        <div class="features text-center">
          <div class="image position-relative d-inline-block">
            <img src="assets/images/icon/intellectual.svg" class="avatar avatar-small" alt="">
          </div>

          <div class="content mt-4">
            <h4 class="title-2">Innovación y Modernización</h4>
            <p class="text-muted mb-0">Soluciones basadas en nuevas tecnologías, ideas novedosas y modernización de sistemas.</p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row">
      <div class="col-lg-4 col-md-6 mb-4 pb-2">
        <div class="media key-feature align-items-center p-3 rounded shadow">
          <div class="icon text-center rounded-circle mr-3">
            <i-feather name="award" class="fea icon-ex-md text-primary"></i-feather>
          </div>
          <div class="media-body">
            <h4 class="title mb-0">Líderes en proyectos con metodologías ágiles</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mb-4 pb-2">
        <div class="media key-feature align-items-center p-3 rounded shadow">
          <div class="icon text-center rounded-circle mr-3">
            <i-feather name="award" class="fea icon-ex-md text-primary"></i-feather>
          </div>
          <div class="media-body">
            <h4 class="title mb-0">Experiencia en uso de metodologías tradicionales</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mb-4 pb-2">
        <div class="media key-feature align-items-center p-3 rounded shadow">
          <div class="icon text-center rounded-circle mr-3">
            <i-feather name="layers" class="fea icon-ex-md text-primary"></i-feather>
          </div>
          <div class="media-body">
            <h4 class="title mb-0">Soluciones modernas basadas en tecnología de punta</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mb-4 pb-2">
        <div class="media key-feature align-items-center p-3 rounded shadow">
          <div class="icon text-center rounded-circle mr-3">
            <i-feather name="user-check" class="fea icon-ex-md text-primary"></i-feather>
          </div>
          <div class="media-body">
            <h4 class="title mb-0">Equipo especializado en innovación y desarrollo</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mb-4 pb-2">
        <div class="media key-feature align-items-center p-3 rounded shadow">
          <div class="icon text-center rounded-circle mr-3">
            <i-feather name="cloud" class="fea icon-ex-md text-primary"></i-feather>
          </div>
          <div class="media-body">
            <h4 class="title mb-0">Especialistas en nube AWS, Google y Azure</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mb-4 pb-2">
        <div class="media key-feature align-items-center p-3 rounded shadow">
          <div class="icon text-center rounded-circle mr-3">
            <i-feather name="git-branch" class="fea icon-ex-md text-primary"></i-feather>
          </div>
          <div class="media-body">
            <h4 class="title mb-0">Integración continua y despliegue continuo</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mb-4 pb-2">
        <div class="media key-feature align-items-center p-3 rounded shadow">
          <div class="icon text-center rounded-circle mr-3">
            <i-feather name="sun" class="fea icon-ex-md text-primary"></i-feather>
          </div>
          <div class="media-body">
            <h4 class="title mb-0">Entendimiento pleno del negocio</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mb-4 pb-2">
        <div class="media key-feature align-items-center p-3 rounded shadow">
          <div class="icon text-center rounded-circle mr-3">
            <i-feather name="shield" class="fea icon-ex-md text-primary"></i-feather>
          </div>
          <div class="media-body">
            <h4 class="title mb-0">Estándares de desarrollo seguro con OWASP</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mb-4 pb-2">
        <div class="media key-feature align-items-center p-3 rounded shadow">
          <div class="icon text-center rounded-circle mr-3">
            <i-feather name="settings" class="fea icon-ex-md text-primary"></i-feather>
          </div>
          <div class="media-body">
            <h4 class="title mb-0">Marcos de trabajo ITIL, COBIT, TOGAF, Zachman</h4>
          </div>
        </div>
      </div>
      <!--end col-->

    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- About End -->

<section class="section pt-0">
  <div class="container">

    <div class="row mt-4 pt-2 position-relative" id="counter" style="z-index: 1;">
      <div class="col-md-2 col-6 mt-4 pt-2"></div>

      <div class="col-md-2 col-6 mt-4 pt-2">
        <div class="counter-box text-center">
          <img src="assets/images/illustrator/Asset190.svg" class="avatar avatar-small" alt="">
          <h2 class="mb-0 mt-4"><span class="counter-value" [CountTo]="100" [from]="1" [duration]="4"></span>%</h2>
          <h6 class="counter-head text-muted">Clientes felices</h6>
        </div>
        <!--end counter box-->
      </div>

      <div class="col-md-2 col-6 mt-4 pt-2">
        <div class="counter-box text-center">
          <img src="assets/images/illustrator/Asset189.svg" class="avatar avatar-small" alt="">
          <h2 class="mb-0 mt-4"><span class="counter-value" [CountTo]="15" [from]="1" [duration]="4"></span>+</h2>
          <h6 class="counter-head text-muted">Reconocimientos</h6>
        </div>
        <!--end counter box-->
      </div>

      <div class="col-md-2 col-6 mt-4 pt-2">
        <div class="counter-box text-center">
          <img src="assets/images/illustrator/Asset192.svg" class="avatar avatar-small" alt="">
          <h2 class="mb-0 mt-4"><span class="counter-value" [CountTo]="80" [from]="0" [duration]="4"></span>%</h2>
          <h6 class="counter-head text-muted">Especialistas</h6>
        </div>
        <!--end counter box-->
      </div>

      <div class="col-md-2 col-6 mt-4 pt-2">
        <div class="counter-box text-center">
          <img src="assets/images/illustrator/Asset187.svg" class="avatar avatar-small" alt="">
          <h2 class="mb-0 mt-4"><span class="counter-value" [CountTo]="30"></span>+</h2>
          <h6 class="counter-head text-muted">Casos de éxito</h6>
        </div>
        <!--end counter box-->
      </div>

      <div class="col-md-3 col-6 mt-4 pt-2"></div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->

<!-- CTA Start -->
<section class="section bg-cta" style="background: url('assets/images/2.jpg') center center;" id="cta">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title title-dark text-white mb-4">Somos Creativos e Innovadores</h4>
          <p class="text-light para-dark para-desc mx-auto">
            Mejoramos continuamente nuestros procesos y servicios, 
            alcanzando el nivel de maduración adecuado para ofrecer entregas de mayor valor a nuestros clientes.  
            Garantizamos el 100% de satisfacción en nuestros productos.
          </p>
          <!--
          <a (click)="opencreative(contentCreactive)" href="javascript:void(0);"
            class="play-btn border border-light mt-4 video-play-icon">
            <i-feather name="play" class="fea icon-ex-md text-white title-dark"></i-feather>
          </a>
          <ng-template #contentCreactive let-modal>
            <div class="modal-header">
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <iframe src="https://player.vimeo.com/video/287684225" height="450" width="780" frameborder="0"
                allowfullscreen allow="autoplay; encrypted-media"></iframe>
            </div>
          </ng-template>
          -->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- CTA End -->

<!-- Price Start -->
<section class="section pt-0">
  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Referencias de Nuestros Clientes</h4>
          <p class="text-muted para-desc mb-0 mx-auto">
            En <span class="text-primary font-weight-bold">MDSIS</span> 
            nos preocupamos por mantener a nuestros clientes siempre felices y satisfechos. 
            Conozca lo que piensan algunos de ellos sobre los servicios ofrecidos por nuestro equipo de trabajo.
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center">
      <div class="col-lg-9 mt-4 pt-2 text-center">
        <!-- <div id="owl-fade" class="owl-carousel owl-theme"> -->
        <owl-carousel-o id="customer-testi" [options]="customOptions">
          
          <ng-template carouselSlide>
            <div class="customer-testi text-center">
              <p class="text-muted h6 font-italic">
                "Excelente trabajo! Se cumplen tiempos y expectativas del servicio. La relación con todo el personal 
                es muy cordial y honesta para todos los servicios requeridos. Los recomendaría siempre."
              </p>
              <ul class="list-unstyled mb-0 mt-3">
                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
              </ul>
              <h6 class="text-primary">Lucta Grancolombiana<br/><small class="text-muted">Jefe de Sistemas - Luz Nelly Leal</small></h6>
            </div>
          </ng-template>

          <ng-template carouselSlide>
            <div class="customer-testi text-center">
              <p class="text-muted h6 font-italic">
                "El equipo MDSIS nos ha brindado una excelente herramienta que ha sido de gran ayuda para la gestión de nuestra organización. Felicitaciones al equipo!"
              </p>
              <ul class="list-unstyled mb-0 mt-3">
                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
              </ul>
              <h6 class="text-primary">Interrapidisimo<br/><small class="text-muted">C.E.O - Mónica Triana</small></h6>
            </div>
          </ng-template>

          <ng-template carouselSlide>
            <div class="customer-testi text-center">
              <p class="text-muted h6 font-italic">
                "La llegada del equipo de MDSIS ha sido muy importante, ya que nos ha permitido 
                mejorar muchos de nuestros procesos internos con su experiencia y sus propuestas
                de cambio. Vamos a poder lograr mejores cosas en el futuro."
              </p>
              <ul class="list-unstyled mb-0 mt-3">
                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
              </ul>
              <h6 class="text-primary"><br/><small class="text-muted">Director de Infraestructura - Robinson Muñoz</small></h6>
            </div>
          </ng-template>

          <!--
          <ng-template carouselSlide>
            <div class="customer-testi text-center">
              <p class="text-muted h6 font-italic">
                "Excelente trabajo! Se cumplen tiempos y expectativas del servicio. La relación con todo el personal 
                es muy cordial y honesta para todos los servicios requeridos. Los recomendaría siempre."
              </p>
              <ul class="list-unstyled mb-0 mt-3">
                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
              </ul>
              <h6 class="text-primary">Domésticas de Colombia<br/><small class="text-muted">C.E.O - María Helena Osorio</small></h6>
            </div>
          </ng-template>

          <ng-template carouselSlide>
            <div class="customer-testi text-center">
              <p class="text-muted h6 font-italic">
                "Excelente trabajo! Se cumplen tiempos y expectativas del servicio. La relación con todo el personal 
                es muy cordial y honesta para todos los servicios requeridos. Los recomendaría siempre."
              </p>
              <ul class="list-unstyled mb-0 mt-3">
                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
              </ul>
              <h6 class="text-primary">Stefanini Group<br/><small class="text-muted">Digital Service Manager - Luz Marina Perez</small></h6>
            </div>
          </ng-template>
          -->

        </owl-carousel-o>
        <!-- </div> -->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!--end Price-->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-light">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->


<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top"
  [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
