import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-works',
  templateUrl: './account-works.component.html',
  styleUrls: ['./account-works.component.css']
})
export class AccountWorksComponent implements OnInit {
  navClass = 'nav-light';
  constructor() { }

  ngOnInit(): void {
  }


}
