<!-- Hero Start -->
<section class="bg-half-170 bg-primary d-table w-100" id="home" style="background: url('assets/images/work/9.jpg') center;background-size: cover;"  id="home">
  <div class="bg-overlay Not"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="title-heading">
          <h1 class="heading text-white title-dark mb-4">Consultoría en TI</h1>
          <p class="para-desc mx-auto text-white-50">
            Nuestra experiencia y trayectoria en proyectos de tecnología, 
            nos permite ofrecer consultoría especializada en diferentes áreas 
            con el propósito de guiar y alinear las necesidades de su organización 
            con tencologías adecuadas, robustas y confiables.
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<!-- FEATURES START -->
<section class="section">
  <div class="container mt-0 mt-0">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-5">
        <div class="position-relative">
          <img src="assets/images/company/about.jpg" class="rounded img-fluid mx-auto d-block" alt="">
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="section-title ml-lg-4">
          <h4 class="title mb-4">Conocimiento en Tecnologías de Información</h4>
          <p class="text-muted">
            En <span class="text-primary font-weight-bold">MDSIS</span> 
            puede contar con profesionales especializados en distintas áreas de tecnología, 
            dispuestos a orientarle en sus necesidades, gracias a nuestra experiencia y 
            profundo conocimiento técnico, gerencial, organizacional, y de negocio.
            <br><br>
            Si tiene una idea y no tiene claro cómo iniciar, o no dispone del tiempo necesario, 
            nosotros le ofrecemos la asesoría y el acompañamiento correspondiente, para ayudarle 
            a estructurar, definir, planear, dirigir, controlar, evaluar, implementar o construir 
            las tecnologías adecuadas para su negocio.            
          </p>
          <ul class="list-unstyled text-muted">
            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>
              Herramientas empresariales</li>
            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>
              Integración empresarial</li>
            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>
              Modernización y nuevas tecnologías</li>
            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>
              Gestión de proyectos de tecnología</li>
            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>
              Procesos de negocio</li>
            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>
              Automatización</li>
            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>
              Analítica, bigdata e inteligencia artificial</li>
          </ul>
          <a href="contact" class="btn btn-primary mt-4"><i class="mdi mdi-phone"></i> Contáctenos</a>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <app-transversales></app-transversales>
  <app-tecnologias></app-tecnologias>

  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="row">
          <div class="col-12 mt-4 pt-2 text-center">
            <a routerLink="/aboutus" class="btn btn-primary mb-2 mr-2">Conózcanos</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end container-->

</section>
<!--end section-->

<section class="section bg-light">

  <div class="container mt-0 mt-0">
    <div class="row align-items-center">
      <div class="col-md-6">
        <div class="section-title text-md-left text-center">
          <h4 class="title mb-4">Inquietudes Sobre el Servicio</h4>
          <p class="text-muted mb-0 para-desc">
            En <span class="text-primary font-weight-bold">MDSIS,</span> 
            nuestro grupo de profesionales está siempre dispuesto a atender sus inquietudes 
            y resolverlas en el menor tiempo. <br><br>
            También puede consultar información de los siguientes servicios:
          </p>

          <ul class="list-unstyled text-muted mt-4 mb-0">
            <li class="mb-0">
              <span class="text-primary h5 mr-2">
                <i class="uim uim-check-circle"></i>
              </span>
              <a routerLink="/sofware-factory" class="text-primary h6">
                Fábrica de Software
              </a>
            </li>
            <li class="mb-0">
              <span class="text-primary h5 mr-2">
                <i class="uim uim-check-circle"></i>
              </span>
              <a routerLink="/automation" class="text-primary h6">
                Automatización
              </a>
            </li>
            <li class="mb-0">
              <span class="text-primary h5 mr-2">
                <i class="uim uim-check-circle"></i>
              </span>
              <a routerLink="/business-intelligence" class="text-primary h6">
                Inteligencia de Negocios
              </a>
            </li>
            <li class="mb-0">
              <span class="text-primary h5 mr-2">
                <i class="uim uim-check-circle"></i>
              </span>
              <a routerLink="/digital-transformation" class="text-primary h6">
                Transformación Digital
              </a>
            </li>
          </ul>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="faq-content">
          <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" closeOthers="true">
            
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingone">
                  <h6 class="title mb-0 text-dark">¿Qué metodologías utilizamos?</h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">
                  Utilizamos todas la herramientas, metodologías, y técnicas posibles en la industria 
                  para garantizar la mayor calidad en el servicio. Nuestros líderes de área 
                  guían y evalúan constantemente el uso de las buenas prácticas según los 
                  marcos de trabajo involucrados en el proyecto.
                  <a routerLink="/aboutus" class="text-primary h6">Más información
                    <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
                  </a>
                </p>
              </ng-template>
            </ngb-panel>
            
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0 text-dark">¿Qué experiencia tenemos en Consultoría?</h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">
                  Contamos con más de 12 años de experiencia en el mercado. Hemos realizado 
                  consultorías en más de 10 proyectos de tecnología, orientando a nuestros clientes 
                  hacia la consecución de los objetivos planteados, la reducción de costos y la 
                  optimización de recursos.
                </p>
              </ng-template>
            </ngb-panel>

            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0 text-dark">¿Qué acompañamiento brindamos?</h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">
                  Acompañamos a los equipos interesados durante toda la idea o proyecto, 
                  desde su etapa inicial hasta su entrega en ambientes productivos. Ofrecemos 
                  asesoría en la definición y validación del proyecto, para garantizar la mejor propuesta 
                  y solución, de acuerdo a las necesidades que tenga la compañía y en pro de las operaciones 
                  del negocio.
                </p>
              </ng-template>
            </ngb-panel>

            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0 text-dark">¿Qué nivel de calidad involucramos?</h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">
                  Implementamos prácticas de integración contínua y despliegue contínuo, acompañado de 
                  evaluación automática de métricas de códificación. Utilizamos planes de pruebas de 
                  calidad con herramientas de automatización, y diseño de pruebas de rendimiento, carga y 
                  estrés, para garantizar los criterios de calidad acordados en todos los componentes de la solución.
                </p>
              </ng-template>
            </ngb-panel>
            
          </ngb-accordion>

        </div>
      </div>
    </div>

    <div class="row mt-2 pt-md-2 justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <a href="contact" class="btn btn-primary mt-4"><i class="mdi mdi-phone"></i> Contáctenos</a>
        </div>
      </div>
    </div>
  
  </div>
  <!--end container-->
</section>
<!--end section-->

<div class="position-relative">
  <div class="shape overflow-hidden text-footer">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top"
  [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
