import { environment } from "src/environments/environment";
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';

export class mdsisCommon {

    public static setMetaTags(title: Title, meta: Meta, data){        
        if(this.evaluarContenido(data.titulo)){
            title.setTitle(data.titulo);
        }

        if(this.evaluarContenido(data.description)){
            this.updateMeta(meta, { name: 'description', content: data.description});
        }

        if(this.evaluarContenido(data.keyworkd)){
            this.updateMeta(meta, { name: 'keywords', content: data.keyworkd});
        }
    }

    private static evaluarContenido(value: any){
        return  value != null && 
                value != "null" &&
                value != undefined &&
                value != "undefined" &&
                value != "";
    }
      
    private static updateMeta(meta: Meta, metaTag: MetaDefinition): void {
        meta.updateTag(metaTag);
    }
      
    public static insertLog(message: any) {
        if (environment.production == false) {
            if(message != undefined){
                console.log(message)
            }
        }
    }
}