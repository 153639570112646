export const mdsisConstants = {

    pathHome: '',
    pathInvalid: '**',

    path_AboutUs: 'aboutus',
    path_ContactUs: 'contact',
    path_SoftwareFactory: 'sofware-factory',
    path_Automation: 'automation',
    path_BusinessIntelligence: 'business-intelligence',
    path_ItConsulting: 'it-consulting',
    path_ProjectManagement: 'project-management',
    path_TalentManagement: 'talent-management',
    path_DigitalTransformation: 'digital-transformation',

    data_Index: {
        titulo: 'MDSIS: Tecnología e innovación para su empresa!', 
        description: 'Hacemos crecer su organización con soluciones modernas, evolutivas y fácilmente adaptables, capaces de afrontar los retos de un mundo cada vez más cambiante y mercados orientados hacia entornos digitales',
        keyworkd: 'MDSIS,tecnologia, technology,sistemas de informacion,information systems,inteligencia_artificial,marketing digital,soluciones bpo,it solutions,soporte de aplicaciones,transformacion e innovacion de negocios,Industria 4.0,soluciones sap,Servicios de soporte de software,soluciones de informatica,consultoria ti,empresa de qa,BPO,RPA,big data analytics,analytics e inteligencia artificial,BPO Soluciones Multicanal,IoT,soluciones de desarrollo de aplicaciones,transformacion cultural,fabrica de desarrollo,fabrica de software,seguridad de la informacion,seguridad de la informacion,gestion de turnos,soluciones de consultoria,desarrollo web,transformacion digital,Desarrollo de aplicaciones,empresa de fabrica de desarrollo,gestion de aplicaciones,empresa de desarrollo de software,servicios de Staffing,desarrollo a la medida,espacio de trabajo e infraestructura,fabrica de software,servicios digitales,automatizacion industrial,workplace services,Servicios de infraestructura,digital,ihm,empresa de tecnologia,desarrollo de software,servicios sap,Servicios agiles,servicios de aplicacion,calidad de software,Staffing,pruebas de software,desarrollo agil,bpo solutions,workplace e infraestructura,outsourcing,staffing,insourcing,personal de tecnologia,talento de ti,ingenieros de desarrollo,ingenieros de sistemas',
    },
    data_AboutUs: {
        titulo: 'Nuestra Empresa', 
        description: 'Contribuimos al progreso acelerado nacional e internacional, en todos los sectores del mercado en los que incursione su compañía.',
        keyworkd: 'tecnología, technology,sistemas de información,information systems,inteligencia_artificial,marketing digital,soluciones bpo,it solutions,soporte de aplicaciones,transformacion e innovacion de negocios,Industria 4.0,soluciones sap,Servicios de soporte de software,soluciones de informatica,consultoria ti,empresa de qa,BPO,RPA,big data analytics,analytics e inteligencia artificial,BPO Soluciones Multicanal,IoT,soluciones de desarrollo de aplicaciones,transformacion cultural,fabrica de desarrollo,fábrica de software,seguridad de la informacion,seguridad de la información,gestion de turnos,soluciones de consultoria,desarrollo web,transformacion digital,Desarrollo de aplicaciones,empresa de fabrica de desarrollo,gestion de aplicaciones,empresa de desarrollo de software,servicios de Staffing,desarrollo a la medida,espacio de trabajo e infraestructura,fabrica de software,servicios digitales,automatizacion industrial,workplace services,Servicios de infraestructura,digital,ihm,empresa de tecnologia,desarrollo de software,servicios sap,Servicios agiles,servicios de aplicación,calidad de software,Staffing,pruebas de software,desarrollo agil,bpo solutions,workplace e infraestructura,outsourcing,staffing,insourcing,personal de tecnología,talento de ti,ingenieros de desarrollo,ingenieros de sistemas',
    },
    data_ContactUs: {
        titulo: 'Contáctenos', 
        description: 'En MDSIS agradecemos su interés en nuestros servicios. Utilice el canal de preferencia para ponerse en contacto con nosotros, y dispondremos del personal necesario para atender su solicitud.',
        keyworkd: 'tecnología, technology,sistemas de información,information systems,inteligencia_artificial,marketing digital,soluciones bpo,it solutions,soporte de aplicaciones,transformacion e innovacion de negocios,Industria 4.0,soluciones sap,Servicios de soporte de software,soluciones de informatica,consultoria ti,empresa de qa,BPO,RPA,big data analytics,analytics e inteligencia artificial,BPO Soluciones Multicanal,IoT,soluciones de desarrollo de aplicaciones,transformacion cultural,fabrica de desarrollo,fábrica de software,seguridad de la informacion,seguridad de la información,gestion de turnos,soluciones de consultoria,desarrollo web,transformacion digital,Desarrollo de aplicaciones,empresa de fabrica de desarrollo,gestion de aplicaciones,empresa de desarrollo de software,servicios de Staffing,desarrollo a la medida,espacio de trabajo e infraestructura,fabrica de software,servicios digitales,automatizacion industrial,workplace services,Servicios de infraestructura,digital,ihm,empresa de tecnologia,desarrollo de software,servicios sap,Servicios agiles,servicios de aplicación,calidad de software,Staffing,pruebas de software,desarrollo agil,bpo solutions,workplace e infraestructura,outsourcing,staffing,insourcing,personal de tecnología,talento de ti,ingenieros de desarrollo,ingenieros de sistemas',
    },
    data_SoftwareFactory: { 
        titulo: 'Fábrica de Software', 
        description: 'Soluciones de acuerdo a sus necesidades, con las mejores buenas prácticas de la industria, alineadas al negocio y con la mayor calidad posible',
        keyworkd: 'software factory,fábrica de software,software a la medida,aplicaciones web, aplicaciones móviles,mobile,integración empresarial,modernización,nube,aws,azure,google cloud,arquitectura moderna,arquitectura evolutiva,RUP,Scrum,Kamban,ITIL,COBIT,TOGAF,Zachman,.Net,Java,Spring Framework,PHP,AngularJS,Angular,Javascript,NodeJS,Python,GO (Google),Scala,WSO2,JBoss Fuse,Apache Camel,Pentaho,JMeter,JUnit,Selenium,Katalon,CI/CD,Jenkins,AzureDevops,Amazon Web Services,AWS,Azure,Google Cloud',
    },
    data_Automation: { 
        titulo: 'Automatización', 
        description: 'Transformamos flujos de trabajo en poderosos procesos automatizados, para ofrecer simplicidad optimizando recursos e incrementando la productividad',
        keyworkd: 'rpa,bpm,automatización,robotica,automation,business process management,roi,inteligencia de negocios,tareas repetitivas,tareas manuales,reducción de costes,eficiencia operativa,proceso eficaz,robot',
    },
    data_BusinessIntelligence: { 
        titulo: 'Inteligencia de Negocios', 
        description: 'La información es el activo más preciado de su organización, y nosotros estamos conscientes de eso. Por ello, tenemos un área especializada en proyectos de análisis y gestión de datos.',
        keyworkd: 'minería de datos,inteligencia de negocios,big data,analítica,business intelligence,datalake,data warehouse,bodega de datos,inteligencia artificial,Artificial intelligence,internet de las cosas,AI,Internet of things,migración de datos,replicación de datos,MDM,master data management,gestión de datos maestros,etl,elt,olap,dimensiones,bases de datos,datos relacionales,datos no estructurados,sql,NoSQL,JSON',
    },
    data_ItConsulting: { 
        titulo: 'Consultoría en TI', 
        description: 'Nuestra experiencia y trayectoria en proyectos de tecnología, nos permite ofrecer consultoría especializada en diferentes áreas con el propósito de guiar y alinear las necesidades de su organización con tencologías adecuadas, robustas y confiables.',
        keyworkd: 'tecnología, technology,sistemas de información,information systems,inteligencia_artificial,marketing digital,soluciones bpo,it solutions,soporte de aplicaciones,transformacion e innovacion de negocios,Industria 4.0,soluciones sap,Servicios de soporte de software,soluciones de informatica,consultoria ti,empresa de qa,BPO,RPA,big data analytics,analytics e inteligencia artificial,BPO Soluciones Multicanal,IoT,soluciones de desarrollo de aplicaciones,transformacion cultural,fabrica de desarrollo,fábrica de software,seguridad de la informacion,seguridad de la información,gestion de turnos,soluciones de consultoria,desarrollo web,transformacion digital,Desarrollo de aplicaciones,empresa de fabrica de desarrollo,gestion de aplicaciones,empresa de desarrollo de software,servicios de Staffing,desarrollo a la medida,espacio de trabajo e infraestructura,fabrica de software,servicios digitales,automatizacion industrial,workplace services,Servicios de infraestructura,digital,ihm,empresa de tecnologia,desarrollo de software,servicios sap,Servicios agiles,servicios de aplicación,calidad de software,Staffing,pruebas de software,desarrollo agil,bpo solutions,workplace e infraestructura,outsourcing,staffing,insourcing,personal de tecnología,talento de ti,ingenieros de desarrollo,ingenieros de sistemas',
    },
    data_ProjectManagement: { 
        titulo: 'Gestión de Proyectos', 
        description: '',
        keyworkd: 'tecnología, technology,sistemas de información,information systems,inteligencia_artificial,marketing digital,soluciones bpo,it solutions,soporte de aplicaciones,transformacion e innovacion de negocios,Industria 4.0,soluciones sap,Servicios de soporte de software,soluciones de informatica,consultoria ti,empresa de qa,BPO,RPA,big data analytics,analytics e inteligencia artificial,BPO Soluciones Multicanal,IoT,soluciones de desarrollo de aplicaciones,transformacion cultural,fabrica de desarrollo,fábrica de software,seguridad de la informacion,seguridad de la información,gestion de turnos,soluciones de consultoria,desarrollo web,transformacion digital,Desarrollo de aplicaciones,empresa de fabrica de desarrollo,gestion de aplicaciones,empresa de desarrollo de software,servicios de Staffing,desarrollo a la medida,espacio de trabajo e infraestructura,fabrica de software,servicios digitales,automatizacion industrial,workplace services,Servicios de infraestructura,digital,ihm,empresa de tecnologia,desarrollo de software,servicios sap,Servicios agiles,servicios de aplicación,calidad de software,Staffing,pruebas de software,desarrollo agil,bpo solutions,workplace e infraestructura',
    },
    data_TalentManagement: { 
        titulo: 'Gestión de Talentos', 
        description: 'Ofrecemos soluciones de dotación de personal especializado en TI, desde la búsqueda y selección, trabajo temporal y outsourcing, a través de consultores especializados en el sector de TI.',
        keyworkd: 'outsourcing,staffing,insourcing,personal de tecnología,talento de ti,ingenieros de desarrollo,ingenieros de sistemas,temporal,permanente,ingenieros,tecnologías de información,sistemas,software,tecnología,recursos humanos,talento humano,people,personal técnico, talento técnico',
    },
    data_DigitalTransformation: { 
        titulo: 'Transformación Digital', 
        description: 'Lo acompañamos en su proceso de transformación hacia la era digital, fortaleciendo su negocio con estrategias de incorporación de nuevas tecnologías, metodologías, y modelos de negocio inteligentes.',
        keyworkd: 'mindset,chip,change,transformation,transformación digital,procesos,personas,tecnología,technology,sistemas de información,information systems,inteligencia_artificial,marketing digital,soluciones bpo,it solutions,soporte de aplicaciones,transformacion e innovacion de negocios,Industria 4.0,soluciones sap,Servicios de soporte de software,soluciones de informatica,consultoria ti,empresa de qa,BPO,RPA,big data analytics,analytics e inteligencia artificial,BPO Soluciones Multicanal,IoT,soluciones de desarrollo de aplicaciones,transformacion cultural,fabrica de desarrollo,fábrica de software,seguridad de la informacion,seguridad de la información,gestion de turnos,soluciones de consultoria,desarrollo web,transformacion digital,Desarrollo de aplicaciones,empresa de fabrica de desarrollo,gestion de aplicaciones,empresa de desarrollo de software,servicios de Staffing,desarrollo a la medida,espacio de trabajo e infraestructura,fabrica de software,servicios digitales,automatizacion industrial,workplace services,Servicios de infraestructura,digital,ihm,empresa de tecnologia,desarrollo de software,servicios sap,Servicios agiles,servicios de aplicación,calidad de software,Staffing,pruebas de software,desarrollo agil,bpo solutions,workplace e infraestructura',
    },
};  