import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-blog-list',
  templateUrl: './page-blog-list.component.html',
  styleUrls: ['./page-blog-list.component.css']
})
export class PageBlogListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


}
