import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Api } from "../providers/api/api";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  @ViewChild('contentSubscribeConfirmation') contentSubscribeConfirmation: ElementRef;
  SubscribeForm = new FormGroup({
    emailsubscribe: new FormControl('', [Validators.required, Validators.maxLength(100)]),
  });

  constructor(private api: Api,
    private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  onFormSubmit(): void {
    if (!this.SubscribeForm.invalid) {
      var obj = { email: this.SubscribeForm.controls.emailsubscribe.value };
      this.api.post<any>(environment.UrlServiceSendSub, obj).subscribe(fm => {  
        this.mostrarConfirmacion();
      },
        (err: any) => {
          if(err.status == 502){
            this.mostrarConfirmacion();
          }
        }
      );
    } else {
      //this.luctaMessageService.messageError('Existe información obligatoria sin diligenciar');
    }
  }

  mostrarConfirmacion(){
    this.modalService.open(this.contentSubscribeConfirmation, { centered: true });
    this.limpiarFormulario();
  }

  limpiarFormulario(){
    this.SubscribeForm.controls.emailsubscribe.setValue("");
  }

}
