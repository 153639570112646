 <!-- Hero Start -->
 <section class="bg-profile d-table w-100 bg-primary" id="home"
   style="background: url('assets/images/account/bg.png') center center;">
   <div class="container">
     <div class="row">
       <div class="col-lg-12">
         <div class="card public-profile border-0 rounded shadow" style="z-index: 1;">
           <div class="card-body">
             <div class="row align-items-center">
               <div class="col-lg-2 col-md-3 text-md-left text-center">
                 <img src="assets/images/client/05.jpg"
                   class="avatar avatar-large rounded-circle shadow d-block mx-auto" alt="">
               </div>
               <!--end col-->

               <div class="col-lg-10 col-md-9">
                 <div class="row align-items-end">
                   <div class="col-md-7 text-md-left text-center mt-4 mt-sm-0">
                     <h3 class="title mb-0">Krista Joseph</h3>
                     <small class="text-muted h6 mr-2">Web Developer</small>
                     <ul class="list-inline mb-0 mt-3">
                       <li class="list-inline-item mr-2"><a href="javascript:void(0)" class="text-muted"
                           title="Instagram">
                           <i-feather name="instagram" class="fea icon-sm mr-2"></i-feather>
                           krista_joseph
                         </a></li>
                       <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted" title="Linkedin">
                           <i-feather name="linkedin" class="fea icon-sm mr-2"></i-feather>
                           Krista Joseph
                         </a></li>
                     </ul>
                   </div>
                   <!--end col-->
                   <div class="col-md-5 text-md-right text-center">
                     <ul class="list-unstyled social-icon social mb-0 mt-4">
                       <li class="list-inline-item"><a href="javascript:void(0)" class="rounded" placement="bottom"
                           ngbTooltip="Add Friend">
                           <i-feather name="user-plus" class="fea icon-sm fea-social"></i-feather>
                         </a></li>
                       <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded" placement="bottom"
                           ngbTooltip="Messages">
                           <i-feather name="message-circle" class="fea icon-sm fea-social"></i-feather>
                         </a></li>
                       <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded" placement="bottom"
                           ngbTooltip="Notifications">
                           <i-feather name="bell" class="fea icon-sm fea-social"></i-feather>
                         </a></li>
                       <li class="list-inline-item ml-1"><a routerLink="/account-setting" class="rounded" placement="bottom"
                           ngbTooltip="Settings">
                           <i-feather name="tool" class="fea icon-sm fea-social"></i-feather>
                         </a></li>
                     </ul>
                     <!--end icon-->
                   </div>
                   <!--end col-->
                 </div>
                 <!--end row-->
               </div>
               <!--end col-->
             </div>
             <!--end row-->
           </div>
         </div>
       </div>
       <!--end col-->
     </div>
     <!--end row-->
   </div>
   <!--ed container-->
 </section>
 <!--end section-->
 <!-- Hero End -->

 <!-- Profile Start -->
 <section class="section mt-60">
   <div class="container mt-lg-3">
     <div class="row">
       <div class="col-lg-4 col-md-6 col-12 d-lg-block d-none">
         <div class="sidebar sticky-bar p-4 rounded shadow">
           <div class="widget">
             <h5 class="widget-title">Followers :</h5>
             <div class="row mt-4">
               <div class="col-6 text-center">
                 <i-feather name="user-plus" class="fea icon-ex-md text-primary mb-1"></i-feather>
                 <h5 class="mb-0">2588</h5>
                 <p class="text-muted mb-0">Followers</p>
               </div>
               <!--end col-->

               <div class="col-6 text-center">
                 <i-feather name="users" class="fea icon-ex-md text-primary mb-1"></i-feather>
                 <h5 class="mb-0">454</h5>
                 <p class="text-muted mb-0">Following</p>
               </div>
               <!--end col-->
             </div>
             <!--end row-->
           </div>

           <div class="widget mt-4 pt-2">
             <h5 class="widget-title">Projects :</h5>
             <div class="progress-box mt-4">
               <h6 class="title text-muted">Progress</h6>
               <div class="progress">
                 <div class="progress-bar position-relative bg-primary" style="width:50%;">
                   <div class="progress-value d-block text-muted h6">24 / 48</div>
                 </div>
               </div>
             </div>
             <!--end process box-->
           </div>

           <div class="widget">
             <div class="row">
               <div class="col-6 mt-4 pt-2">
                 <a routerLink="/account-profile" class="accounts rounded d-block shadow text-center py-3">
                   <span class="pro-icons h3 text-muted">
                     <i class="uil uil-dashboard"></i></span>
                   <h6 class="title text-dark h6 my-0">Profile</h6>
                 </a>
               </div>
               <!--end col-->

               <div class="col-6 mt-4 pt-2">
                 <a routerLink="/account-members" class="accounts active rounded d-block shadow text-center py-3">
                   <span class="pro-icons h3 text-muted">
                     <i class="uil uil-users-alt"></i></span>
                   <h6 class="title text-dark h6 my-0">Members</h6>
                 </a>
               </div>
               <!--end col-->

               <div class="col-6 mt-4 pt-2">
                 <a routerLink="/account-works" class="accounts rounded d-block shadow text-center py-3">
                   <span class="pro-icons h3 text-muted">
                     <i class="uil uil-file"></i></span>
                   <h6 class="title text-dark h6 my-0">Works</h6>
                 </a>
               </div>
               <!--end col-->

               <div class="col-6 mt-4 pt-2">
                 <a routerLink="/account-messages" class="accounts rounded d-block shadow text-center py-3">
                   <span class="pro-icons h3 text-muted">
                     <i class="uil uil-envelope-star"></i></span>
                   <h6 class="title text-dark h6 my-0">Messages</h6>
                 </a>
               </div>
               <!--end col-->

               <div class="col-6 mt-4 pt-2">
                 <a routerLink="/account-payments" class="accounts rounded d-block shadow text-center py-3">
                   <span class="pro-icons h3 text-muted">
                     <i class="uil uil-transaction"></i></span>
                   <h6 class="title text-dark h6 my-0">Payments</h6>
                 </a>
               </div>
               <!--end col-->

               <div class="col-6 mt-4 pt-2">
                 <a routerLink="/account-setting" class="accounts rounded d-block shadow text-center py-3">
                   <span class="pro-icons h3 text-muted">
                     <i class="uil uil-setting"></i></span>
                   <h6 class="title text-dark h6 my-0">Settings</h6>
                 </a>
               </div>
               <!--end col-->

               <div class="col-6 mt-4 pt-2">
                 <a routerLink="/auth-login-three" class="accounts rounded d-block shadow text-center py-3">
                   <span class="pro-icons h3 text-muted">
                     <i class="uil uil-sign-out-alt"></i></span>
                   <h6 class="title text-dark h6 my-0">Logout</h6>
                 </a>
               </div>
               <!--end col-->
             </div>
             <!--end row-->
           </div>

           <div class="widget mt-4 pt-2">
             <h5 class="widget-title">Follow me :</h5>
             <ul class="list-unstyled social-icon mb-0 mt-4">
               <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                   <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                 </a></li>
               <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                   <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                 </a></li>
               <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                   <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                 </a></li>
               <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                   <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                 </a></li>
               <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                   <i-feather name="github" class="fea icon-sm fea-social"></i-feather>
                 </a></li>
               <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                   <i-feather name="youtube" class="fea icon-sm fea-social"></i-feather>
                 </a></li>
               <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                   <i-feather name="gitlab" class="fea icon-sm fea-social"></i-feather>
                 </a></li>
             </ul>
             <!--end icon-->
           </div>
         </div>
       </div>
       <!--end col-->

       <div class="col-lg-8 col-12">
         <div class="rounded shadow p-4">
           <h5>Members:</h5>

           <div class="row">
             <div class="col-lg-6 col-md-4 mt-4 pt-2">
               <div class="text-center">
                 <img src="assets/images/client/01.jpg" class="avatar avatar-medium shadow-lg rounded-pill" alt="">
                 <div class="content mt-3">
                   <a href="javascript:void(0)" class="h5 text-dark">Calvin Carlo</a>
                   <ul class="list-unstyled social-icon social mb-0 mt-2">
                     <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                         <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                       </a></li>
                     <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                         <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                       </a></li>
                     <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                         <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                       </a></li>
                     <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                         <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                       </a></li>
                   </ul>
                   <!--end icon-->
                   <a href="javascript:void(0)" class="btn btn-primary">
                     <i-feather name="mail" class="fea icon-sm"></i-feather>
                     Send Message
                   </a>
                 </div>
               </div>
             </div>
             <!--end col-->

             <div class="col-lg-6 col-md-4 mt-4 pt-2">
               <div class="text-center">
                 <img src="assets/images/client/02.jpg" class="avatar avatar-medium shadow-lg rounded-pill" alt="">
                 <div class="content mt-3">
                   <a href="javascript:void(0)" class="h5 text-dark">Calvin Carlo</a>
                   <ul class="list-unstyled social-icon social mb-0 mt-2">
                     <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                         <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                       </a></li>
                     <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                         <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                       </a></li>
                     <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                         <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                       </a></li>
                     <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                         <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                       </a></li>
                   </ul>
                   <!--end icon-->
                   <a href="javascript:void(0)" class="btn btn-primary">
                     <i-feather name="mail" class="fea icon-sm"></i-feather>
                     Send Message
                   </a>
                 </div>
               </div>
             </div>
             <!--end col-->

             <div class="col-lg-6 col-md-4 mt-4 pt-2">
               <div class="text-center">
                 <img src="assets/images/client/03.jpg" class="avatar avatar-medium shadow-lg rounded-pill" alt="">
                 <div class="content mt-3">
                   <a href="javascript:void(0)" class="h5 text-dark">Calvin Carlo</a>
                   <ul class="list-unstyled social-icon social mb-0 mt-2">
                     <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                         <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                       </a></li>
                     <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                         <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                       </a></li>
                     <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                         <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                       </a></li>
                     <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                         <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                       </a></li>
                   </ul>
                   <!--end icon-->
                   <a href="javascript:void(0)" class="btn btn-primary">
                     <i-feather name="mail" class="fea icon-sm"></i-feather>
                     Send Message
                   </a>
                 </div>
               </div>
             </div>
             <!--end col-->

             <div class="col-lg-6 col-md-4 mt-4 pt-2">
               <div class="text-center">
                 <img src="assets/images/client/04.jpg" class="avatar avatar-medium shadow-lg rounded-pill" alt="">
                 <div class="content mt-3">
                   <a href="javascript:void(0)" class="h5 text-dark">Calvin Carlo</a>
                   <ul class="list-unstyled social-icon social mb-0 mt-2">
                     <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                         <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                       </a></li>
                     <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                         <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                       </a></li>
                     <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                         <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                       </a></li>
                     <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                         <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                       </a></li>
                   </ul>
                   <!--end icon-->
                   <a href="javascript:void(0)" class="btn btn-primary">
                     <i-feather name="mail" class="fea icon-sm"></i-feather>
                     Send Message
                   </a>
                 </div>
               </div>
             </div>
             <!--end col-->

             <div class="col-lg-6 col-md-4 mt-4 pt-2">
               <div class="text-center">
                 <img src="assets/images/client/05.jpg" class="avatar avatar-medium shadow-lg rounded-pill" alt="">
                 <div class="content mt-3">
                   <a href="javascript:void(0)" class="h5 text-dark">Calvin Carlo</a>
                   <ul class="list-unstyled social-icon social mb-0 mt-2">
                     <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                         <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                       </a></li>
                     <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                         <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                       </a></li>
                     <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                         <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                       </a></li>
                     <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                         <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                       </a></li>
                   </ul>
                   <!--end icon-->
                   <a href="javascript:void(0)" class="btn btn-primary">
                     <i-feather name="mail" class="fea icon-sm"></i-feather>
                     Send Message
                   </a>
                 </div>
               </div>
             </div>
             <!--end col-->

             <div class="col-lg-6 col-md-4 mt-4 pt-2">
               <div class="text-center">
                 <img src="assets/images/client/06.jpg" class="avatar avatar-medium shadow-lg rounded-pill" alt="">
                 <div class="content mt-3">
                   <a href="javascript:void(0)" class="h5 text-dark">Calvin Carlo</a>
                   <ul class="list-unstyled social-icon social mb-0 mt-2">
                     <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                         <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                       </a></li>
                     <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                         <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                       </a></li>
                     <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                         <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                       </a></li>
                     <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                         <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                       </a></li>
                   </ul>
                   <!--end icon-->
                   <a href="javascript:void(0)" class="btn btn-primary">
                     <i-feather name="mail" class="fea icon-sm"></i-feather>
                     Send Message
                   </a>
                 </div>
               </div>
             </div>
             <!--end col-->

             <div class="col-lg-6 col-md-4 mt-4 pt-2">
               <div class="text-center">
                 <img src="assets/images/client/07.jpg" class="avatar avatar-medium shadow-lg rounded-pill" alt="">
                 <div class="content mt-3">
                   <a href="javascript:void(0)" class="h5 text-dark">Calvin Carlo</a>
                   <ul class="list-unstyled social-icon social mb-0 mt-2">
                     <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                         <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                       </a></li>
                     <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                         <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                       </a></li>
                     <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                         <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                       </a></li>
                     <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                         <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                       </a></li>
                   </ul>
                   <!--end icon-->
                   <a href="javascript:void(0)" class="btn btn-primary">
                     <i-feather name="mail" class="fea icon-sm"></i-feather>
                     Send Message
                   </a>
                 </div>
               </div>
             </div>
             <!--end col-->

             <div class="col-lg-6 col-md-4 mt-4 pt-2">
               <div class="text-center">
                 <img src="assets/images/client/08.jpg" class="avatar avatar-medium shadow-lg rounded-pill" alt="">
                 <div class="content mt-3">
                   <a href="javascript:void(0)" class="h5 text-dark">Calvin Carlo</a>
                   <ul class="list-unstyled social-icon social mb-0 mt-2">
                     <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                         <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                       </a></li>
                     <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                         <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                       </a></li>
                     <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                         <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                       </a></li>
                     <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                         <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                       </a></li>
                   </ul>
                   <!--end icon-->
                   <a href="javascript:void(0)" class="btn btn-primary">
                     <i-feather name="mail" class="fea icon-sm"></i-feather>
                     Send Message
                   </a>
                 </div>
               </div>
             </div>
             <!--end col-->
           </div>
           <!--end row-->
         </div>
       </div>
       <!--end col-->
     </div>
     <!--end row-->
   </div>
   <!--end container-->
 </section>
 <!--end section-->
 <!-- Profile End -->

 <!-- Back to top -->
 <a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top"
   [ngxScrollTo]="'#home'">
   <i-feather name="arrow-up" class="icons"></i-feather>
 </a>
 <!-- Back to top -->
