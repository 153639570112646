 <!-- Hero Start -->
 <section class="bg-half bg-light d-table w-100" id="home">
   <div class="container">
     <div class="row justify-content-center">
       <div class="col-lg-12 text-center">
         <div class="page-next-level">
           <h4 class="title"> Submit your Support Request </h4>

           <div class="page-next">
             <nav aria-label="breadcrumb" class="d-inline-block">
               <ul class="breadcrumb bg-white rounded shadow mb-0">
                 <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                 <li class="breadcrumb-item"><a routerLink="/helpcenter-overview">Help Center</a></li>
                 <li class="breadcrumb-item active" aria-current="page">Support</li>
               </ul>
             </nav>
           </div>
         </div>
       </div>
       <!--end col-->
     </div>
     <!--end row-->
   </div>
   <!--end container-->
 </section>
 <!--end section-->
 <!-- Hero End -->

 <!-- Start Section -->
 <section class="section">
   <div class="container">
     <div class="row justify-content-center">
       <div class="col-lg-7 col-12">
         <div class="rounded p-4 shadow">
           <div class="row">
             <div class="col-12">
               <form>
                 <div class="row">
                   <div class="col-md-6">
                     <div class="form-group position-relative">
                       <label>Your Name <span class="text-danger">*</span></label>
                       <i-feather name="user" class="fea icon-sm icons"></i-feather>
                       <input name="name" id="name" type="text" class="form-control pl-5" placeholder="First Name :">
                     </div>
                   </div>
                   <!--end col-->
                   <div class="col-md-6">
                     <div class="form-group position-relative">
                       <label>Your Email <span class="text-danger">*</span></label>
                       <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                       <input name="email" id="email" type="email" class="form-control pl-5" placeholder="Your email :">
                     </div>
                   </div>
                   <!--end col-->
                   <div class="col-md-12">
                     <div class="form-group position-relative">
                       <label>Subject</label>
                       <i-feather name="book" class="fea icon-sm icons"></i-feather>
                       <input name="subject" id="subject" class="form-control pl-5" placeholder="Your subject :">
                     </div>
                   </div>
                   <!--end col-->
                   <div class="col-md-12">
                     <div class="form-group position-relative">
                       <label>Comments</label>
                       <i-feather name="message-circle" class="fea icon-sm icons"></i-feather>
                       <textarea name="comments" id="comments" rows="4" class="form-control pl-5"
                         placeholder="Your Message :"></textarea>
                     </div>
                   </div>
                 </div>
                 <!--end row-->
                 <div class="row">
                   <div class="col-sm-12">
                     <input type="submit" id="submit" name="send" class="btn btn-primary" value="Send Request">
                   </div>
                   <!--end col-->
                 </div>
                 <!--end row-->
               </form>
               <!--end form-->
             </div>
             <!--end col-->
           </div>
           <!--end row-->
         </div>
       </div>
       <!--end col-->
     </div>
     <!--end row-->
   </div>
   <!--end container-->

   <div class="container mt-100 mt-60">
     <div class="row">
       <div class="col-lg-6 col-md-6 col-12">
         <div class="media align-items-center shadow rounded p-4 features">
           <div class="icons m-0 rounded h2 text-primary text-center px-3">
             <i class="uil uil-envelope-check"></i>
           </div>
           <div class="content ml-4">
             <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Get in Touch !</a></h5>
             <p class="text-muted mb-0">This is required when, for text is not yet available.</p>
             <div class="mt-2">
               <a href="javascript:void(0)" class="btn btn-sm btn-soft-primary">Submit a Request</a>
             </div>
           </div>
         </div>
       </div>
       <!--end col-->

       <div class="col-lg-6 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
         <div class="media align-items-center shadow rounded p-4 features">
           <div class="icons m-0 rounded h2 text-primary text-center px-3">
             <i class="uil uil-webcam"></i>
           </div>
           <div class="content ml-4">
             <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Start a Meeting</a></h5>
             <p class="text-muted mb-0">This is required when, for text is not yet available.</p>
             <div class="mt-2">
               <a href="javascript:void(0)" class="btn btn-sm btn-soft-primary">Start Video Chat</a>
             </div>
           </div>
         </div>
       </div>
       <!--end col-->
     </div>
     <!--end row-->
   </div>
   <!--end container-->
 </section>
 <!--end section-->
 <!-- End Section -->



 <!-- Back to top -->
 <a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top"
   [ngxScrollTo]="'#home'">
   <i-feather name="arrow-up" class="icons"></i-feather>
 </a>
 <!-- Back to top -->
