import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';

import { Title, Meta } from '@angular/platform-browser';
import { mdsisCommon } from 'src/app/shared/common/mdsis.common';
import { mdsisConstants } from 'src/app/shared/common/mdsis.constants';

@Component({
  selector: 'app-index-modern-business',
  templateUrl: './index-modern-business.component.html',
  styleUrls: ['./index-modern-business.component.css']
})
export class IndexModernBusinessComponent implements OnInit {
  navClass = 'nav-light';
  constructor(private modalService: NgbModal,
    private scrollToService: ScrollToService,
    public title: Title, private meta: Meta) { }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      900: {
        items: 1
      }
    },
    nav: false
  };

  /**
  * Open modal for show the video
  */
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }
  openModal(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }
  opencreative(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }
  ngOnInit(): void {
    mdsisCommon.setMetaTags(this.title,this.meta, mdsisConstants.data_Index);
    this.scrollToService.scrollTo({ target: 'home' });
  }



}
