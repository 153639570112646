import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-helpcenter-support-request',
  templateUrl: './helpcenter-support-request.component.html',
  styleUrls: ['./helpcenter-support-request.component.css']
})
export class HelpcenterSupportRequestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


}
