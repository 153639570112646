import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-contact-two',
  templateUrl: './page-contact-two.component.html',
  styleUrls: ['./page-contact-two.component.css']
})
export class PageContactTwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


}
