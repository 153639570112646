<!-- Hero Start -->
<section class="bg-half-170 bg-primary d-table w-100" id="home" style="background: url('assets/images/Fondos/fd7.jpg') center;background-size: cover;"  id="home">
  <div class="bg-overlay Not"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="title-heading">
          <h1 class="heading text-white title-dark mb-4">Inteligencia de Negocios</h1>
          <p class="para-desc mx-auto text-white-50">
            La información es el activo más preciado de su organización, 
            y nosotros estamos conscientes de eso. Por ello, tenemos un área 
            especializada en proyectos de análisis y gestión de datos.
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<!-- FEATURES START -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="features-absolute" style="margin: -260px 0 0px;">
          <div class="row">
            

            <div class="col-md-12 mt-4 pt-2">
              <div class="media features p-4 bg-white shadow rounded">
                <div class="icons m-0 rounded h2 text-primary text-center px-3" style="background: none;">
                  <img _ngcontent-bav-c185="" src="assets/images/illustrator/BI1.jpg" alt="" class="avatar avatar-small">
                </div>
                <div class="content ml-4">
                  <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Análisis de Información Empresarial</a></h5>
                  <p class="text-muted mb-0">
                    Analizamos datos estructurados y no estructurados al interior de su organización, para proporcionar información 
                    consolidada que sea de utilidad para la toma de decisiones y definición de nuevas estrategias de negocios. 
                    Apoyados en arquitectos de información con gran experiencia, nuestro equipo de trabajo lo asesorará y guiará en 
                    el aprovechamiento de sus datos.
                  </p>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-md-12 mt-4 pt-2">
              <div class="media features p-4 bg-white shadow rounded">
                <div class="icons m-0 rounded h2 text-primary text-center px-3" style="background: none;">
                  <img _ngcontent-bav-c185="" src="assets/images/illustrator/ETL.png" alt="" class="avatar avatar-small">
                </div>
                <div class="content ml-4">
                  <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Integración de Alto Volumen de Información</a></h5>
                  <p class="text-muted mb-0">
                    Dado que muchas organizaciones no solo utilizan un único sistema de información, con el tiempo se vuelve cada 
                    vez más complejo sacar provecho de la información almacenada en cada uno de ellos. Sincronización, mantenimiento, 
                    respaldo, y demás actividades relacionadas pueden involucrar esfuerzo y costos importantes. Con nuestra ayuda, 
                    podrá identificar puntos de mejora y sincronía de la información, desde simples hasta complejas bodegas de datos.
                  </p>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-md-12 mt-4 pt-2">
              <div class="media features p-4 bg-white shadow rounded">
                <div class="icons m-0 rounded h2 text-primary text-center px-3" style="background: none;">
                  <img _ngcontent-bav-c185="" src="assets/images/illustrator/MDM.png" alt="" class="avatar avatar-small">
                </div>
                <div class="content ml-4">
                  <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Administración de Datos Maestros</a></h5>
                  <p class="text-muted mb-0">
                    Si su organización utiliza varios sistemas de información, como es usual, entenderá que en muchos casos 
                    existe información que es común entre las diferentes aplicaciones, y según el modo en que los gestione 
                    puede que tenga que lidiar con muchos dolores de cabeza. 
                    Ya que somos expertos en información, estamos en capacidad de proponer diferentes estrategias de 
                    administración y gestión de datos maestros, que le permitirán optimizar flujos de comunicación y  
                    dependencias entre aplicaciones, así como disponer de sistemas de información altamente estables y escalables. 

                  </p>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-md-12 mt-4 pt-2">
              <div class="media features p-4 bg-white shadow rounded">
                <div class="icons m-0 rounded h2 text-primary text-center px-3" style="background: none;">
                  <img _ngcontent-bav-c185="" src="assets/images/illustrator/IA.svg" alt="" class="avatar avatar-small">
                </div>
                <div class="content ml-4">
                  <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Inteligencia Artificial y BigData</a></h5>
                  <p class="text-muted mb-0">
                    Contamos con científicos de datos que aprovecharán al máximo los registros de sus operaciones, 
                    para obtener información descriptiva y predictiva, de manera que no solo pueda ser utilizada para tomar 
                    mejores decisiones, sino también, sean tomadas en el momento exacto para el fortalecimiento de su organización.
                    Somos expertos en analítica descriptiva, analítica diagnóstica, analítica predictiva, analítica prescriptiva 
                    e inteligencia artificial (IA).
                  </p>
                </div>
              </div>
            </div>
            <!--end col-->

          </div>
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="row">
          <div class="col-12 mt-4 pt-2 text-center">
            <a routerLink="/aboutus" class="btn btn-primary mb-2 mr-2">Conózcanos</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end container-->

</section>
<!--end section-->

<section class="section bg-light">

  <div class="container mt-0 mt-0">
    <div class="row align-items-center">
      <div class="col-md-6">
        <div class="section-title text-md-left text-center">
          <h4 class="title mb-4">Inquietudes Sobre el Servicio</h4>
          <p class="text-muted mb-0 para-desc">
            En <span class="text-primary font-weight-bold">MDSIS,</span> 
            nuestro grupo de profesionales está siempre dispuesto a atender sus inquietudes 
            y resolverlas en el menor tiempo. <br><br>
            También puede consultar información de los siguientes servicios:
          </p>

          <ul class="list-unstyled text-muted mt-4 mb-0">
            <li class="mb-0">
              <span class="text-primary h5 mr-2">
                <i class="uim uim-check-circle"></i>
              </span>
              <a routerLink="/sofware-factory" class="text-primary h6">
                Fábrica de Software
              </a>
            </li>
            <li class="mb-0">
              <span class="text-primary h5 mr-2">
                <i class="uim uim-check-circle"></i>
              </span>
              <a routerLink="/automation" class="text-primary h6">
                Automatización
              </a>
            </li>
            <li class="mb-0">
              <span class="text-primary h5 mr-2">
                <i class="uim uim-check-circle"></i>
              </span>
              <a routerLink="/digital-transformation" class="text-primary h6">
                Transformación Digital
              </a>
            </li>
            <li class="mb-0">
              <span class="text-primary h5 mr-2">
                <i class="uim uim-check-circle"></i>
              </span>
              <a routerLink="/it-consulting" class="text-primary h6">
                Consultoría en TI
              </a>
            </li>
          </ul>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="faq-content">
          <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" closeOthers="true">
            
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingone">
                  <h6 class="title mb-0 text-dark">¿Qué metodologías utilizamos? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">
                  Utilizamos todas la herramientas, metodologías, y técnicas posibles en la industria 
                  para garantizar la mayor calidad en el servicio. Nuestros líderes de área 
                  guían y evalúan constantemente el uso de las buenas prácticas según los 
                  marcos de trabajo involucrados en el proyecto.
                  <a routerLink="/aboutus" class="text-primary h6">Más información
                    <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
                  </a>
                </p>
              </ng-template>
            </ngb-panel>
            
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0 text-dark">¿Qué experiencia tenemos en Fábrica de Software? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">
                  Hemos aportado gran valor a nuestros clientes en los últimos años, en proyectos analítica de datos para diferentes 
                  industrias como: Salud, Educación, Retail, Jurídico, Financiero y Gobierno. 
                </p>
              </ng-template>
            </ngb-panel>

            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0 text-dark">¿Qué acompañamiento brindamos? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">
                  Acompañamos a los equipos interesados durante todo el proyecto, desde su 
                  etapa inicial hasta su entrega en ambientes productivos. Ofrecemos 
                  asesoría en la definición y validación del proyecto, para garantizar la mejor propuesta 
                  y solución, de acuerdo a las necesidades que tenga la compañía y en pro de las operaciones 
                  del negocio. Consulte también nuestro servicio de 
                  <a routerLink="/it-consulting" class="text-primary h6">Consultoría en TI
                    <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
                  </a>
                </p>
              </ng-template>
            </ngb-panel>

            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0 text-dark">¿Qué nivel de calidad involucramos? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">
                  Implementamos prácticas de integración contínua y despliegue contínuo, acompañado de 
                  evaluación automática de métricas de códificación. Utilizamos planes de pruebas de 
                  calidad con herramientas de automatización, y diseño de pruebas de rendimiento, carga y 
                  estrés, para garantizar los criterios de calidad acordados en todos los componentes de la solución.
                </p>
              </ng-template>
            </ngb-panel>
            
          </ngb-accordion>

        </div>
      </div>
    </div>

    <div class="row mt-2 pt-md-2 justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <a href="contact" class="btn btn-primary mt-4"><i class="mdi mdi-phone"></i> Contáctenos</a>
        </div>
      </div>
    </div>
  
  </div>
  <!--end container-->
</section>
<!--end section-->

<div class="position-relative">
  <div class="shape overflow-hidden text-footer">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top"
  [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
