import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * Api is a generic REST Api handler. Set your API url first.
 */
@Injectable()
export class Api 
{
  headers = new HttpHeaders();

  constructor(public http: HttpClient) 
  {
  }

  get<T>(endpoint: string, params: HttpParams): Observable<HttpResponse<T>> 
  {
    this.InitHeaders();
    let reqOpts = {
      headers: this.headers,
      params: params
      ,observe: "response" as 'body'
    };
    return this.http.get<HttpResponse<T>>(endpoint, reqOpts);
  }

  post<T>(endpoint: string, params: any): Observable<HttpResponse<T>>  
  {
    this.InitHeaders();
    let reqOpts = {
      headers: this.headers,
      withCredentials: false
      ,observe: "response" as 'body'
    };
    return this.http.post<HttpResponse<T>>(endpoint, params, reqOpts);
  }

  put<T>(endpoint: string, params: any): Observable<HttpResponse<T>>  
  {
    this.InitHeaders();
    let reqOpts = {
      headers: this.headers,
      withCredentials: false
      ,observe: "response" as 'body'
    };
    return this.http.put<HttpResponse<T>>(endpoint, params, reqOpts);
  }

  postFile(endpoint: string, params: any) 
  {
    this.InitHeaders();
    return this.http.post(endpoint, params, { withCredentials: false, params: params, headers: this.headers, responseType: 'blob' });
  }
  
  delete<T>(endpoint: string, params?: any): Observable<HttpResponse<T>> 
  {
    this.InitHeaders();

    var reqOpts = {
      params: params,
      headers: this.headers,
      withCredentials: false
      ,observe: "response" as 'body'
    };
    return this.http.delete<HttpResponse<T>>(endpoint, reqOpts);
  }

  private InitHeaders()
  {
    this.headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
  }
}
