<!-- Hero Start -->
<section class="bg-home d-flex align-items-center" style="height: auto;" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center mt-0 mt-md-5 pt-0 pt-md-5">
        <div class="title-heading margin-top-100">
          <h1 class="heading font-weight-bold mb-3">We Build A Design <br> For Email Inbox</h1>
          <p class="para-desc mx-auto text-muted">Launch your campaign and benefit from our expertise on designing and
            managing conversion centered bootstrap4 html page.</p>
          <div class="mt-4 pt-2">
            <a href="#about" class="btn btn-primary">Start Free Trial <i class="mdi mdi-chevron-right"></i></a>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-lg-10">
            <div class="home-dashboard">
              <img src="assets/images/email/home.png" alt="" class="img-fluid rounded-md shadow-lg"
                style="z-index: -1;">
            </div>
          </div>
        </div>
        <!--end row-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Partners start -->
<section class="section py-4 bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-2 col-md-2 col-6 text-center py-4">
        <img src="assets/images/client/amazon.svg" class="avatar avatar-ex-sm" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-2 col-md-2 col-6 text-center py-4">
        <img src="assets/images/client/google.svg" class="avatar avatar-ex-sm" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-2 col-md-2 col-6 text-center py-4">
        <img src="assets/images/client/lenovo.svg" class="avatar avatar-ex-sm" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-2 col-md-2 col-6 text-center py-4">
        <img src="assets/images/client/paypal.svg" class="avatar avatar-ex-sm" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-2 col-md-2 col-6 text-center py-4">
        <img src="assets/images/client/shopify.svg" class="avatar avatar-ex-sm" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-2 col-md-2 col-6 text-center py-4">
        <img src="assets/images/client/spotify.svg" class="avatar avatar-ex-sm" alt="">
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Partners End -->

<!-- Start -->
<section class="section border-bottom">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h4 class="title mb-4">Our Features</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary font-weight-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-lg-4 col-md-6 mt-5">
        <div class="media features">
          <div class="icons m-0 rounded h2 text-primary text-center px-3">
            <i class="uil uil-comment-verify"></i>
          </div>
          <div class="content ml-4">
            <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Instant Chat</a></h5>
            <p class="text-muted mb-0">The most well-known dummy text is the, which is said to have originated in the
              16th century.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-5">
        <div class="media features">
          <div class="icons m-0 rounded h2 text-primary text-center px-3">
            <i class="uil uil-image"></i>
          </div>
          <div class="content ml-4">
            <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Media Messages</a></h5>
            <p class="text-muted mb-0">The most well-known dummy text is the, which is said to have originated in the
              16th century.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-5">
        <div class="media features">
          <div class="icons m-0 rounded h2 text-primary text-center px-3">
            <i class="uil uil-video"></i>
          </div>
          <div class="content ml-4">
            <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Video Messages</a></h5>
            <p class="text-muted mb-0">The most well-known dummy text is the, which is said to have originated in the
              16th century.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-5">
        <div class="media features">
          <div class="icons m-0 rounded h2 text-primary text-center px-3">
            <i class="uil uil-database-alt"></i>
          </div>
          <div class="content ml-4">
            <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Dedicated Server</a></h5>
            <p class="text-muted mb-0">The most well-known dummy text is the, which is said to have originated in the
              16th century.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-5">
        <div class="media features">
          <div class="icons m-0 rounded h2 text-primary text-center px-3">
            <i class="uil uil-users-alt"></i>
          </div>
          <div class="content ml-4">
            <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Team Collaboration</a></h5>
            <p class="text-muted mb-0">The most well-known dummy text is the, which is said to have originated in the
              16th century.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-5">
        <div class="media features">
          <div class="icons m-0 rounded h2 text-primary text-center px-3">
            <i class="uil uil-apps"></i>
          </div>
          <div class="content ml-4">
            <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Responsive App</a></h5>
            <p class="text-muted mb-0">The most well-known dummy text is the, which is said to have originated in the
              16th century.</p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center">
      <div class="col-12 mt-5">
        <div class="watch-video text-center">
          <a href="javascript:void(0)" class="btn btn-primary mb-2 mr-2">Get Started <i
              class="mdi mdi-chevron-right"></i></a>
          <a (click)="openWindowCustomClass(content)" href="javascript:void(0);"
            class="video-play-icon watch title-dark text-dark mb-2"><i
              class="mdi mdi-play play-icon-circle text-center d-inline-block mr-2 rounded-circle text-white title-dark position-relative play play-iconbar"></i>
            WATCH VIDEO</a>
          <ng-template #content let-modal>
            <div class="modal-header">
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <iframe src="https://player.vimeo.com/video/99025203" height="450" width="780" frameborder="0"
                allowfullscreen allow="autoplay; encrypted-media"></iframe>
            </div>
          </ng-template>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>

<section class="section">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-6">
        <img src="assets/images/email/1.png" class="img-fluid" alt="">
      </div>
      <!--end col-->

      <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title ml-lg-5">
          <h4 class="title mb-4">Make your website <br> growth with next level visitors</h4>
          <p class="text-muted">You can combine all the Landrick templates into a single one, you can take a component
            from the Application theme and use it in the Website.</p>
          <ul class="list-unstyled text-muted">
            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>Digital
              Marketing Solutions for Tomorrow</li>
            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>Our Talented
              & Experienced Marketing Agency</li>
            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>Create your
              own skin to match your brand</li>
          </ul>
          <a href="javascript:void(0)" class="mt-3 h6 text-primary">Find Out More <i
              class="mdi mdi-chevron-right"></i></a>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-md-6 order-1 order-md-2">
        <img src="assets/images/illustrator/inbox-cleanup.svg" alt="">
      </div>
      <!--end col-->

      <div class="col-md-6 order-2 order-md-1">
        <div class="section-title ml-lg-5">
          <h4 class="title mb-4">Meet our business <br> partner who work behind the scene</h4>
          <p class="text-muted">You can combine all the Landrick templates into a single one, you can take a component
            from the Application theme and use it in the Website.</p>
          <ul class="list-unstyled text-muted">
            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>Digital
              Marketing Solutions for Tomorrow</li>
            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>Our Talented
              & Experienced Marketing Agency</li>
            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>Create your
              own skin to match your brand</li>
          </ul>
          <a href="javascript:void(0)" class="mt-3 h6 text-primary">Find Out More <i
              class="mdi mdi-chevron-right"></i></a>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Whats Our Clients Said About <span class="text-primary">Landrick</span> Project</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary font-weight-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center">
      <div class="col-lg-12 mt-4">
        <!-- <div id="customer-testi" class="owl-carousel owl-theme"> -->
        <owl-carousel-o id="customer-testi" [options]="customOptions">
          <ng-template carouselSlide>
            <div class="media customer-testi m-2">
              <img src="assets/images/client/01.jpg" class="avatar avatar-small mr-3 rounded shadow" alt="">
              <div class="media-body content p-3 shadow rounded bg-white position-relative">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                </ul>
                <p class="text-muted mt-2">" It seems that only fragments of the original text remain in the Lorem Ipsum
                  texts used today. "</p>
                <h6 class="text-primary">- Thomas Israel <small class="text-muted">C.E.O</small></h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="media customer-testi m-2">
              <img src="assets/images/client/02.jpg" class="avatar avatar-small mr-3 rounded shadow" alt="">
              <div class="media-body content p-3 shadow rounded bg-white position-relative">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star-half text-warning"></i></li>
                </ul>
                <p class="text-muted mt-2">" One disadvantage of Lorum Ipsum is that in Latin certain letters appear
                  more frequently than others. "</p>
                <h6 class="text-primary">- Barbara McIntosh <small class="text-muted">M.D</small></h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="media customer-testi m-2">
              <img src="assets/images/client/03.jpg" class="avatar avatar-small mr-3 rounded shadow" alt="">
              <div class="media-body content p-3 shadow rounded bg-white position-relative">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                </ul>
                <p class="text-muted mt-2">" The most well-known dummy text is the 'Lorem Ipsum', which is said to have
                  originated in the 16th century. "</p>
                <h6 class="text-primary">- Carl Oliver <small class="text-muted">P.A</small></h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="media customer-testi m-2">
              <img src="assets/images/client/04.jpg" class="avatar avatar-small mr-3 rounded shadow" alt="">
              <div class="media-body content p-3 shadow rounded bg-white position-relative">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                </ul>
                <p class="text-muted mt-2">" According to most sources, Lorum Ipsum can be traced back to a text
                  composed by Cicero. "</p>
                <h6 class="text-primary">- Christa Smith <small class="text-muted">Manager</small></h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="media customer-testi m-2">
              <img src="assets/images/client/05.jpg" class="avatar avatar-small mr-3 rounded shadow" alt="">
              <div class="media-body content p-3 shadow rounded bg-white position-relative">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                </ul>
                <p class="text-muted mt-2">" There is now an abundance of readable dummy texts. These are usually used
                  when a text is required. "</p>
                <h6 class="text-primary">- Dean Tolle <small class="text-muted">Developer</small></h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="media customer-testi m-2">
              <img src="assets/images/client/06.jpg" class="avatar avatar-small mr-3 rounded shadow" alt="">
              <div class="media-body content p-3 shadow rounded bg-white position-relative">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                </ul>
                <p class="text-muted mt-2">" Thus, Lorem Ipsum has only limited suitability as a visual filler for
                  German texts. "</p>
                <h6 class="text-primary">- Jill Webb <small class="text-muted">Designer</small></h6>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
        <!-- </div> -->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- Start Plan -->
<section class="section bg-light">
  <div class="container">
    <div class="row align-items-end mb-4 pb-2">
      <div class="">
        <div class="section-title text-center text-md-left">
          <h4 class="title mb-4">Choose the best <br> one for you</h4>
          <p class="text-muted mb-0 para-desc">Start working with <span
              class="text-primary font-weight-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->

    </div>
    <div class="text-center text-md-right">

      <ul ngbNav #nav="ngbNav"
        class="nav-pills bg-white rounded-lg justify-content-center d-inline-block shadow py-1 px-2 mb-0">
        <li ngbNavItem class="d-inline-block">
          <a ngbNavLink class="nav-link px-3 rounded-lg monthly" id="Monthly" role="tab" aria-controls="Month"
            aria-selected="true">Monthly</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                <div class="card pricing-rates business-rate shadow bg-light border-0 rounded">
                  <div class="card-body">
                    <h2 class="title text-uppercase mb-4">Free</h2>
                    <div class="d-flex mb-4">
                      <span class="h6 mb-0 mt-2">$</span>
                      <span class="price h1 mb-0">0</span>
                      <span class="h5 align-self-end mb-1"> /mo</span>
                    </div>

                    <ul class="list-unstyled mb-0 pl-0">
                      <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                            class="uim uim-check-circle"></i></span>Full Access</li>
                      <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                            class="uim uim-check-circle"></i></span>Source Files</li>
                    </ul>
                    <a href="javascript:void(0)" class="btn btn-primary mt-4">Buy Now</a>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                <div class="card pricing-rates business-rate shadow bg-white border-0 rounded">
                  <div class="card-body">
                    <h2 class="title text-uppercase text-primary mb-4">Starter</h2>
                    <div class="d-flex mb-4">
                      <span class="h6 mb-0 mt-2">$</span>
                      <span class="price h1 mb-0">39</span>
                      <span class="h5 align-self-end mb-1"> /mo</span>
                    </div>

                    <ul class="list-unstyled mb-0 pl-0">
                      <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                            class="uim uim-check-circle"></i></span>Full Access</li>
                      <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                            class="uim uim-check-circle"></i></span>Source Files</li>
                      <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                            class="uim uim-check-circle"></i></span>Free Appointments</li>
                    </ul>
                    <a href="javascript:void(0)" class="btn btn-primary mt-4">Get Started</a>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                <div class="card pricing-rates business-rate shadow bg-light border-0 rounded">
                  <div class="card-body">
                    <h2 class="title text-uppercase mb-4">Professional</h2>
                    <div class="d-flex mb-4">
                      <span class="h6 mb-0 mt-2">$</span>
                      <span class="price h1 mb-0">59</span>
                      <span class="h5 align-self-end mb-1"> /mo</span>
                    </div>

                    <ul class="list-unstyled mb-0 pl-0">
                      <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                            class="uim uim-check-circle"></i></span>Full Access</li>
                      <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                            class="uim uim-check-circle"></i></span>Source Files</li>
                      <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                            class="uim uim-check-circle"></i></span>1 Domain Free</li>
                      <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                            class="uim uim-check-circle"></i></span>Enhanced Security</li>
                    </ul>
                    <a href="javascript:void(0)" class="btn btn-primary mt-4">Try It Now</a>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                <div class="card pricing-rates business-rate shadow bg-light border-0 rounded">
                  <div class="card-body">
                    <h2 class="title text-uppercase mb-4">Ultimate</h2>
                    <div class="d-flex mb-4">
                      <span class="h6 mb-0 mt-2">$</span>
                      <span class="price h1 mb-0">79</span>
                      <span class="h5 align-self-end mb-1"> /mo</span>
                    </div>

                    <ul class="list-unstyled mb-0 pl-0">
                      <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                            class="uim uim-check-circle"></i></span>Full Access</li>
                      <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                            class="uim uim-check-circle"></i></span>Enhanced Security</li>
                      <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                            class="uim uim-check-circle"></i></span>Source Files</li>
                      <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                            class="uim uim-check-circle"></i></span>1 Domain Free</li>
                      <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                            class="uim uim-check-circle"></i></span>Free Installment</li>
                    </ul>
                    <a href="javascript:void(0)" class="btn btn-primary mt-4">Started Now</a>
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
          </ng-template>
        </li>
        <li ngbNavItem class="d-inline-block">
          <a ngbNavLink class="nav-link px-3 rounded-lg monthly" id="Yearly" role="tab" aria-controls="Month"
            aria-selected="true">Yearly</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                <div class="card pricing-rates business-rate shadow bg-light border-0 rounded">
                  <div class="card-body">
                    <h2 class="title text-uppercase mb-4">Free</h2>
                    <div class="d-flex mb-4">
                      <span class="h6 mb-0 mt-2">$</span>
                      <span class="price h1 mb-0">0</span>
                      <span class="h5 align-self-end mb-1"> /mo</span>
                    </div>

                    <ul class="list-unstyled mb-0 pl-0">
                      <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                            class="uim uim-check-circle"></i></span>Full Access</li>
                      <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                            class="uim uim-check-circle"></i></span>Source Files</li>
                    </ul>
                    <a href="javascript:void(0)" class="btn btn-primary mt-4">Buy Now</a>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                <div class="card pricing-rates business-rate shadow bg-white border-0 rounded">
                  <div class="card-body">
                    <h2 class="title text-uppercase text-primary mb-4">Starter</h2>
                    <div class="d-flex mb-4">
                      <span class="h6 mb-0 mt-2">$</span>
                      <span class="price h1 mb-0">39</span>
                      <span class="h5 align-self-end mb-1"> /mo</span>
                    </div>

                    <ul class="list-unstyled mb-0 pl-0">
                      <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                            class="uim uim-check-circle"></i></span>Full Access</li>
                      <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                            class="uim uim-check-circle"></i></span>Source Files</li>
                      <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                            class="uim uim-check-circle"></i></span>Free Appointments</li>
                    </ul>
                    <a href="javascript:void(0)" class="btn btn-primary mt-4">Get Started</a>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                <div class="card pricing-rates business-rate shadow bg-light border-0 rounded">
                  <div class="card-body">
                    <h2 class="title text-uppercase mb-4">Professional</h2>
                    <div class="d-flex mb-4">
                      <span class="h6 mb-0 mt-2">$</span>
                      <span class="price h1 mb-0">59</span>
                      <span class="h5 align-self-end mb-1"> /mo</span>
                    </div>

                    <ul class="list-unstyled mb-0 pl-0">
                      <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                            class="uim uim-check-circle"></i></span>Full Access</li>
                      <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                            class="uim uim-check-circle"></i></span>Source Files</li>
                      <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                            class="uim uim-check-circle"></i></span>1 Domain Free</li>
                      <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                            class="uim uim-check-circle"></i></span>Enhanced Security</li>
                    </ul>
                    <a href="javascript:void(0)" class="btn btn-primary mt-4">Try It Now</a>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                <div class="card pricing-rates business-rate shadow bg-light border-0 rounded">
                  <div class="card-body">
                    <h2 class="title text-uppercase mb-4">Ultimate</h2>
                    <div class="d-flex mb-4">
                      <span class="h6 mb-0 mt-2">$</span>
                      <span class="price h1 mb-0">79</span>
                      <span class="h5 align-self-end mb-1"> /mo</span>
                    </div>

                    <ul class="list-unstyled mb-0 pl-0">
                      <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                            class="uim uim-check-circle"></i></span>Full Access</li>
                      <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                            class="uim uim-check-circle"></i></span>Enhanced Security</li>
                      <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                            class="uim uim-check-circle"></i></span>Source Files</li>
                      <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                            class="uim uim-check-circle"></i></span>1 Domain Free</li>
                      <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                            class="uim uim-check-circle"></i></span>Free Installment</li>
                    </ul>
                    <a href="javascript:void(0)" class="btn btn-primary mt-4">Started Now</a>
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav"></div>
    </div>

    <!--end col-->
  </div>
  <!--end row-->


  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-5 col-12">
        <img src="assets/images/illustrator/envelope.svg" class="img-fluid mx-auto d-block" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-7 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title">
          <div class="alert alert-light alert-pills" role="alert">
            <span class="badge badge-primary badge-pill mr-1">Apps</span>
            <span class="content">Download now
              <i-feather name="chevron-right" class="fea icon-sm"></i-feather>
            </span>
          </div>
          <h4 class="title mb-4">Available for your <br> Smartphones</h4>
          <p class="text-muted para-desc mb-0">Start working with <span
              class="text-primary font-weight-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
          <div class="my-4">
            <a href="javascript:void(0)" class="btn btn-lg btn-dark mt-2 mr-2"><i class="mdi mdi-apple"></i> App
              Store</a>
            <a href="javascript:void(0)" class="btn btn-lg btn-dark mt-2"><i class="mdi mdi-google-play"></i> Play
              Store</a>
          </div>

          <div class="d-inline-block">
            <div class="pt-4 d-flex align-items-center border-top">
              <i-feather name="smartphone" class="fea icon-md mr-2 text-primary"></i-feather>
              <div class="content">
                <h6 class="mb-0">Install app now on your cellphones</h6>
                <a href="javascript:void(0)" class="text-primary">Learn More <i class="mdi mdi-arrow-right"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Plan -->


<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top"
  [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
