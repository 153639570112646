<!-- Hero Start -->
<section class="bg-half d-table w-100" id="home" style="background: url('assets/images/company/aboutus.jpg');">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level title-heading">
          <h1 class="text-white title-dark"> Nuestra Empresa </h1>
          <p class="text-white-50 para-desc mb-0 mx-auto">
            Conócenos y descubre quiénes somos y todo lo que hacemos constantemente
            para brindarte el mejor servicio.
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<section class="section border-bottom">
  <div class="container">
    <div class="row align-items-center" id="counter">
      <div class="col-md-6">
        <img src="assets/images/company/about2.png" class="img-fluid" alt="">
      </div>
      <!--end col-->

      <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="ml-lg-4">
          <div class="d-flex mb-4">
            <span class="text-primary h1 mb-0"><span class="counter-value display-1 font-weight-bold"
              [CountTo]="12"
              [from]="0" [duration]="4"></span>+</span>
            <span class="h6 align-self-end ml-2">Años<br> de experiencia</span>
          </div>
          <div class="section-title">
            <h4 class="title mb-4">¿Quiénes somos?</h4>
            <p class="text-muted">
              <span class="text-primary font-weight-bold">MDSIS SAS</span> 
              nace por la alianza de diferentes compañías, donde sus directivos unen esfuerzos para potenciar la Industria Colombiana de Software, 
              con el profundo deseo de orientar sus habilidades, experiencia y trayectoria, en la formulación de nuevas ideas que permitan potenciar a las organizaciones
              con ayuda de tecnologías de punta y sistemas modernos, facilitando la operatividad, el crecimiento y el fortalecimiento del negocio.
              <br><br>
              Contribuimos al progreso acelerado nacional e internacional, en todos los sectores del mercado en los que incursione su compañía. 
            </p>
            <a routerLink="/contact" class="btn btn-primary mt-3">Contáctenos</a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->

<section class="section border-bottom">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h6 class="text-primary">Servicios con Calidad</h6>
          <h4 class="title mb-4">¿Cómo lo hacemos?</h4>
          <p class="text-muted para-desc mx-auto mb-0">
            Todos los servicios ofrecidos por <span class="text-primary font-weight-bold">MDSIS</span> 
            están basados en los procesos sugeridos en el marco de buenas prácticas de ITIL: <br>
            <span class="text-primary font-weight-bold">ITIL Service Management</span><br>  
            <span class="text-primary font-weight-bold">ITIL Application Management</span><br>
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>

  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="">
          <div class="row">
            
            <div class="col-lg-3 col-md-6 col-12">
              <div class="card features fea-primary rounded pt-4 pb-4 pl-1 pr-1  bg-light text-center position-relative overflow-hidden border-0">
                <span class="h1 icon2 text-primary">
                  <i class="uil uil-airplay"></i>
                </span>
                <div class="card-body p-0 content">
                  <h5>Estrategia</h5>
                  <p class="text-muted">
                    Generación de la Estrategia​<br>
                    Gestión Financiera​​<br>
                    Gestión de la Demanda​​<br>
                    Gestión de la Cartera de Servicios​​<br>
                  </p>
                </div>
                <span class="big-icon text-center">
                  <i class="uil uil-airplay"></i>
                </span>
              </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 col-12">
              <div class="card features fea-primary rounded pt-4 pb-4 pl-1 pr-1  bg-light text-center position-relative overflow-hidden border-0">
                <span class="h1 icon2 text-primary">
                  <i class="uil uil-clipboard-alt"></i>
                </span>
                <div class="card-body p-0 content">
                  <h5>Diseño</h5>
                  <p class="text-muted">
                    Catálogo de Servicios<br>
                    Niveles del Servicio​<br>
                      Capacidad​ - Continuidad<br>
                      Seguridad - Proveedores<br>
                  </p>
                </div>
                <span class="big-icon text-center">
                  <i class="uil uil-clipboard-alt"></i>
                </span>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-12">
              <div class="card features fea-primary rounded pt-4 pb-4 pl-1 pr-1  bg-light text-center position-relative overflow-hidden border-0">
                <span class="h1 icon2 text-primary">
                  <i class="uil uil-credit-card-search" ></i>
                </span>
                <div class="card-body p-0 content">
                  <h5>Transición</h5>
                  <p class="text-muted">
                      Conocimiento - Configuración<br>
                    Planificación de transición​<br>
                      Gestión del Cambio<br>
                      Evaluación<br>
                  </p>
                </div>
                <span class="big-icon text-center">
                  <i class="uil uil-credit-card-search" ></i>
                </span>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-12">
              <div class="card features fea-primary rounded pt-4 pb-4 pl-1 pr-1 bg-light text-center position-relative overflow-hidden border-0">
                <span class="h1 icon2 text-primary">
                  <i class="uil uil-ruler-combined" ></i>
                </span>
                <div class="card-body p-0 content">
                  <h5>Operación</h5>
                  <p class="text-muted">
                    Incidencias - Problemas<br>
                    Eventos - Cumplimiento<br>
                    Centro de Servicios y Operaciones<br>
                    Aplicaciones - Gestión Técnica<br>
                  </p>
                </div>
                <span class="big-icon text-center">
                  <i class="uil uil-ruler-combined" ></i>
                </span>
              </div>
            </div>
            <!--end col-->
          </div>
          
          <br>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">

              <div class="card features fea-primary rounded pt-4 pb-4 pl-1 pr-1  bg-light text-center position-relative overflow-hidden border-0">
                <div class="card-body p-0 content">
                  <h5>Mejora Contínua</h5>
                  <div class="row pl-3 pr-3">
                    <div class="col-lg-4 col-md-12 col-12">
                      <div class="card features explore-feature px-md-1 border-0 rounded-md shadow text-center pt-1 pb-1" style="background-color: #f9f9f9;">                
                        <div class="card-body p-0 content">
                          <h5 class="mt-2 mb-2"><a href="javascript:void(0)" class="title text-dark">Medición del Servicio</a></h5>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-12 col-12">
                      <div class="card features explore-feature px-md-1 border-0 rounded-md shadow text-center pt-1 pb-1" style="background-color: #f9f9f9;">                
                        <div class="card-body p-0 content">
                          <h5 class="mt-2 mb-2"><a href="javascript:void(0)" class="title text-dark">Informes del Servicio</a></h5>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-12 col-12">
                      <div class="card features explore-feature px-md-1 border-0 rounded-md shadow text-center pt-1 pb-1" style="background-color: #f9f9f9;">                
                        <div class="card-body p-0 content">
                          <h5 class="mt-2 mb-2"><a href="javascript:void(0)" class="title text-dark">Mejoras del servicio</a></h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--end row-->
  </div>
  
  <app-transversales></app-transversales>

</section>
<!--end section-->

<!-- Start -->
<section class="section pb-0">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h6 class="text-primary">Satisfacción y Felicidad</h6>
          <h4 class="title mb-4">Nuestros Clientes</h4>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      
      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="card blog rounded border-0 shadow">
          <div class="position-relative">
            <img src="assets/images/work/clients/1.png" class="card-img-top rounded-top" alt="...">
            <div class="overlay rounded-top bg-dark"></div>
          </div>
          <div class="author">
            <small class="text-light user d-block"><i class="mdi mdi-account"></i> Carvajal Tecnología</small>
            <small class="text-light user d-block"><i class="mdi mdi-account"></i> Fábrica de Software</small>
            <small class="text-light date"><i class="mdi mdi-calendar-check"></i> Septiembre, 2019</small>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="card blog rounded border-0 shadow">
          <div class="position-relative">
            <img src="assets/images/work/clients/12.jpg" class="card-img-top rounded-top" alt="...">
            <div class="overlay rounded-top bg-dark"></div>
          </div>
          <div class="author">
            <small class="text-light user d-block"><i class="mdi mdi-account"></i> Stefanini Group</small>
            <small class="text-light user d-block"><i class="mdi mdi-account"></i> Consultoría en TI</small>
            <small class="text-light date"><i class="mdi mdi-calendar-check"></i> Febrero, 2022</small>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="card blog rounded border-0 shadow">
          <div class="position-relative">
            <img src="assets/images/work/clients/5.jpg" class="card-img-top rounded-top" alt="...">
            <div class="overlay rounded-top bg-dark"></div>
          </div>
          <div class="author">
            <small class="text-light user d-block"><i class="mdi mdi-account"></i> Lucta Grancolombiana</small>
            <small class="text-light user d-block"><i class="mdi mdi-account"></i> Fábrica - Automatización - Inteligencia de Negocios</small>
            <small class="text-light date"><i class="mdi mdi-calendar-check"></i> Julio, 2020</small>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="card blog rounded border-0 shadow">
          <div class="position-relative">
            <img src="assets/images/work/clients/9.jpg" class="card-img-top rounded-top" alt="...">
            <div class="overlay rounded-top bg-dark"></div>
          </div>
          <div class="author">
            <small class="text-light user d-block"><i class="mdi mdi-account"></i> Easy Services</small>
            <small class="text-light user d-block"><i class="mdi mdi-account"></i> Fábrica de Software</small>
            <small class="text-light date"><i class="mdi mdi-calendar-check"></i> Julio, 2018</small>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="card blog rounded border-0 shadow">
          <div class="position-relative">
            <img src="assets/images/work/clients/11.jpg" class="card-img-top rounded-top" alt="...">
            <div class="overlay rounded-top bg-dark"></div>
          </div>
          <div class="author">
            <small class="text-light user d-block"><i class="mdi mdi-account"></i> Bricks4Us</small>
            <small class="text-light user d-block"><i class="mdi mdi-account"></i> Fábrica de Software - Automatización</small>
            <small class="text-light date"><i class="mdi mdi-calendar-check"></i> Julio, 2018</small>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="card blog rounded border-0 shadow">
          <div class="position-relative">
            <img src="assets/images/work/clients/8.jpg" class="card-img-top rounded-top" alt="...">
            <div class="overlay rounded-top bg-dark"></div>
          </div>
          <div class="author">
            <small class="text-light user d-block"><i class="mdi mdi-account"></i> Zencillo Software</small>
            <small class="text-light user d-block"><i class="mdi mdi-account"></i> Fábrica de Software - Automatización</small>
            <small class="text-light date"><i class="mdi mdi-calendar-check"></i> Julio, 2017</small>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="card blog rounded border-0 shadow">
          <div class="position-relative">
            <img src="assets/images/work/clients/2.png" class="card-img-top rounded-top" alt="...">
            <div class="overlay rounded-top bg-dark"></div>
          </div>
          <div class="author">
            <small class="text-light user d-block"><i class="mdi mdi-account"></i> Interrapidísimo</small>
            <small class="text-light user d-block"><i class="mdi mdi-account"></i> Fábrica de Software</small>
            <small class="text-light date"><i class="mdi mdi-calendar-check"></i> Enero, 2010</small>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="card blog rounded border-0 shadow">
          <div class="position-relative">
            <img src="assets/images/work/clients/3.png" class="card-img-top rounded-top" alt="...">
            <div class="overlay rounded-top bg-dark"></div>
          </div>
          <div class="author">
            <small class="text-light user d-block"><i class="mdi mdi-account"></i> DNI Developers</small>
            <small class="text-light user d-block"><i class="mdi mdi-account"></i> Fábrica de Software</small>
            <small class="text-light date"><i class="mdi mdi-calendar-check"></i> Enero, 2019</small>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="card blog rounded border-0 shadow">
          <div class="position-relative">
            <img src="assets/images/work/clients/10.jpg" class="card-img-top rounded-top" alt="...">
            <div class="overlay rounded-top bg-dark"></div>
          </div>
          <div class="author">
            <small class="text-light user d-block"><i class="mdi mdi-account"></i> Domésticas de Colombia</small>
            <small class="text-light user d-block"><i class="mdi mdi-account"></i> Fábrica - Automatización - Inteligencia de Negocios</small>
            <small class="text-light date"><i class="mdi mdi-calendar-check"></i> Julio, 2020</small>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="card blog rounded border-0 shadow">
          <div class="position-relative">
            <img src="assets/images/work/clients/7.png" class="card-img-top rounded-top" alt="...">
            <div class="overlay rounded-top bg-dark"></div>
          </div>
          <div class="author">
            <small class="text-light user d-block"><i class="mdi mdi-account"></i> Escuela Superior de Guerra</small>
            <small class="text-light user d-block"><i class="mdi mdi-account"></i> Consultoría en TI</small>
            <small class="text-light date"><i class="mdi mdi-calendar-check"></i> Octubre, 2017</small>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="card blog rounded border-0 shadow">
          <div class="position-relative">
            <img src="assets/images/work/clients/4.jpg" class="card-img-top rounded-top" alt="...">
            <div class="overlay rounded-top bg-dark"></div>
          </div>
          <div class="author">
            <small class="text-light user d-block"><i class="mdi mdi-account"></i> Comando de Reclutamiento Ejercito de Colombia</small>
            <small class="text-light user d-block"><i class="mdi mdi-account"></i> Fábrica de Software</small>
            <small class="text-light date"><i class="mdi mdi-calendar-check"></i> Marzo, 2017</small>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="card blog rounded border-0 shadow">
          <div class="position-relative">
            <img src="assets/images/work/clients/6.png" class="card-img-top rounded-top" alt="...">
            <div class="overlay rounded-top bg-dark"></div>
          </div>
          <div class="author">
            <small class="text-light user d-block"><i class="mdi mdi-account"></i> Transmilenio</small>
            <small class="text-light user d-block"><i class="mdi mdi-account"></i> Fábrica de Software</small>
            <small class="text-light date"><i class="mdi mdi-calendar-check"></i> Enero, 2018</small>
          </div>
        </div>
      </div>
      <!--end col-->

    </div>

    <!--
    <div class="projects-wrapper mt-4 pt-2">
      <ngx-masonry [options]="myOptions" class="row">
        <div ngxMasonryItem class="col-lg-3 col-md-6 col-12 px-0 masonry-item" *ngFor="let item of filterredImages">
          <div class="card border-0 work-container work-modern position-relative d-block overflow-hidden rounded-0">
            <div class="card-body p-0">
              <img src="{{item.image}}" class="img-fluid" alt="work-image">
              <div class="overlay-work bg-dark"></div>
              <div class="content">
                <h5 class="mb-0"><a routerLink="/page-work-detail" class="text-white title">{{item.title}}</a></h5>
                <h6 class="text-light tag mb-0">{{item.text}}</h6>
              </div>
              <div class="icons text-center">
                <a href="javascript: void(0);"
                  class="text-primary work-icon bg-white d-inline-block rounded-pill mfp-image">
                  <i-feather name="camera" class="fea icon-sm"></i-feather>
                </a>
              </div>
            </div>
          </div>
        </div>
      </ngx-masonry>
    </div>
    -->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End -->

<section class="section pt-0">
  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h4 class="title mb-4">Nuestros Líderes</h4>
          <p class="text-muted para-desc mx-auto mb-0">
            Nos apasiona lo que hacemos, y nos motiva constantemente la 
            evolución y el mejoramiento de nuestros clientes. 
          </p>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-lg-12 mt-4">
        <!-- <div id="customer-testi" class="owl-carousel owl-theme"> -->
        <owl-carousel-o id="customer-testi" [options]="customOptions">
          
          <ng-template carouselSlide>
            <div class="media customer-testi m-2">
              <img src="assets/images/client/05.jpg" class="avatar avatar-small mr-3 rounded shadow" alt="">
              <div class="media-body content p-3 shadow rounded bg-white position-relative">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                </ul>
                <p class="text-muted mt-2">
                  "Administradora de empresas, especialista en alta gerencia 
                  y gerencia de talento humano de la Universidad Externado 
                  de Colombia, con más de 10 años de experiencia en dirección 
                  administrativa, financiera y de personal."
                </p>
                <h6 class="text-primary">Paola Tenorio <br><small class="text-muted">C.E.O</small></h6>
              </div>
            </div>
          </ng-template>

          <ng-template carouselSlide>
            <div class="media customer-testi m-2">
              <img src="assets/images/client/01.jpg" class="avatar avatar-small mr-3 rounded shadow" alt="">
              <div class="media-body content p-3 shadow rounded bg-white position-relative">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                </ul>
                <p class="text-muted mt-2">
                  "Ingeniero especialista en arquitectura empresarial de software de la Universidad 
                  Javeriana, con más de 12 años de experiencia en proyectos de 
                  tecnología, dirección de equipos, formación técnica y pensamiento estratégico."
                </p>
                <h6 class="text-primary">Luis Alfonso Ruge <br><small class="text-muted">Arquitectura Empresarial</small></h6>
              </div>
            </div>
          </ng-template>

          <ng-template carouselSlide>
            <div class="media customer-testi m-2">
              <img src="assets/images/client/08.jpg" class="avatar avatar-small mr-3 rounded shadow" alt="">
              <div class="media-body content p-3 shadow rounded bg-white position-relative">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                </ul>
                <p class="text-muted mt-2">
                  "Especialista en gerencia de proyectos de servicios con TIC de la 
                  Universidad del Rosario, con amplia trayectoria en marketing, dirección de ventas, 
                  proyectos de tecnología, gestión por procesos y planeación estratégica."
                </p>
                <h6 class="text-primary">Diego Fernando Ruge <br><small class="text-muted">Negocios Digitales</small></h6>
              </div>
            </div>
          </ng-template>

          <ng-template carouselSlide>
            <div class="media customer-testi m-2">
              <img src="assets/images/client/04.jpg" class="avatar avatar-small mr-3 rounded shadow" alt="">
              <div class="media-body content p-3 shadow rounded bg-white position-relative">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                </ul>
                <p class="text-muted mt-2">
                  "Arquitecto de software de la Universidad de los Andes, 
                  con más de 15 años de experiencia en construcción de sistemas de información, 
                  modernización y transformación digital."
                </p>
                <h6 class="text-primary">Diego Andrés Nieto <br><small class="text-muted">Líder de Arquitectura</small></h6>
              </div>
            </div>
          </ng-template>        

          <ng-template carouselSlide>
            <div class="media customer-testi m-2">
              <img src="assets/images/client/02.jpg" class="avatar avatar-small mr-3 rounded shadow" alt="">
              <div class="media-body content p-3 shadow rounded bg-white position-relative">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                </ul>
                <p class="text-muted mt-2">
                  "Profesional en psicología, especialista en el área organizacional, 
                  líder de procesos de selección, interacción con el trabajo, y 
                  salud mental de los colaboradores en las compañías."
                </p>
                <h6 class="text-primary">Angie Stefanía Mendez <br><small class="text-muted">People & Happiness</small></h6>
              </div>
            </div>
          </ng-template>

          <ng-template carouselSlide>
            <div class="media customer-testi m-2">
              <img src="assets/images/client/03.jpg" class="avatar avatar-small mr-3 rounded shadow" alt="">
              <div class="media-body content p-3 shadow rounded bg-white position-relative">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                </ul>
                <p class="text-muted mt-2">
                  "Especialista en ingeniería de software de la Universidad Javeriana, 
                  con gran experiencia en soluciones tecnológicas, automatización, modernización 
                  e infraestructura."
                </p>
                <h6 class="text-primary">Nohemí Perea Machado <br><small class="text-muted">Líder Técnico de Proyectos</small></h6>
              </div>
            </div>
          </ng-template>

        </owl-carousel-o>
      </div>
    </div>

  </div>
  <!--end container-->
</section>
<!--end section-->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top"
  [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
