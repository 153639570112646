import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-messages',
  templateUrl: './account-messages.component.html',
  styleUrls: ['./account-messages.component.css']
})
export class AccountMessagesComponent implements OnInit {
  navClass = 'nav-light';
  constructor() { }

  ngOnInit(): void {
  }



}
