<!-- Hero Start -->
<section class="bg-half-170 bg-primary d-table w-100" id="home" style="background: url('assets/images/Fondos/fd16.jpg') center;background-size: cover;"  id="home">
  <div class="bg-overlay Not"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="title-heading">
          <h1 class="heading text-white title-dark mb-4">Transformación Digital</h1>
          <p class="para-desc mx-auto text-white-50">
            Lo acompañamos en su proceso de transformación hacia la era digital, 
            fortaleciendo su negocio con estrategias de incorporación de nuevas tecnologías, 
            metodologías, y modelos de negocio inteligentes.
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<!-- FEATURES START -->
<section class="section">
  <div class="container mt-0 mt-0">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-5">
        <div class="position-relative">
          <img src="assets/images/company/about.jpg" class="rounded img-fluid mx-auto d-block" alt="">
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="section-title ml-lg-4">
          <h4 class="title mb-4">Empoderamiento y Cambio</h4>
          <p class="text-muted">
            En <span class="text-primary font-weight-bold">MDSIS</span> 
            no solo innovamos con tecnología, también involucramos al personal de 
            su organización en un cambio cultural y de enfoque sobre cómo realizar 
            las operaciones de manera más eficiente, promoviendo un negocio cada vez 
            más sostenible, adaptable, rentable y productivo. 
            <br><br>
            Propiciamos cambios de mindset, transformación cultural, modelos de negocio, 
            así como el impacto de la operación en la vida de las personas.
          </p>
          <ul class="list-unstyled text-muted">
            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>
              Redescubrimiento del propósito</li>
            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>
              Una mirada hacia afuera: mercado, contexto y competencia</li>
            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>
              Experimentación</li>
            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>
              Colaboración</li>
            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>
              Empoderamiento</li>
            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>
              Refinamiento</li>
          </ul>
          <a routerLink="/aboutus" class="btn btn-primary mb-2 mr-2">Conózcanos</a>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mt-100 pb-0">
          <h4 class="title mb-4">Modelo de Transformación Digital</h4>
          <p class="text-muted para-desc mb-0 mx-auto">
            En <span class="text-primary font-weight-bold">MDSIS,</span> 
            nos centramos en las personas, la tecnología y los negocios, 
            para promover capacidades de negocio y potenciar la oferta de valor.             
          </p>
        </div>
      </div>
      <!--end col-->
      
      <div class="col-lg-2 col-md-12 col-12 mt-4 pt-2">
        <div class="card explore-feature border-0 rounded text-center bg-white">
          <div class="card-body py-3">
            <div class="icon rounded-circle shadow-lg d-inline-block">
              <i-feather name="aperture" class="fea"></i-feather>
            </div>
            <div class="content mt-3">
              <h5><a href="javascript:void(0)" class="title text-dark">Visón</a></h5>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-12 col-12 mt-4 pt-2">
        <div class="card explore-feature border-0 rounded text-center bg-white">
          <div class="card-body py-3">
            <div class="icon rounded-circle shadow-lg d-inline-block">
              <i-feather name="users" class="fea"></i-feather>
            </div>
            <div class="content mt-3">
              <h5><a href="javascript:void(0)" class="title text-dark">Personas</a></h5>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-12 col-12 mt-4 pt-2">
        <div class="card explore-feature border-0 rounded text-center bg-white">
          <div class="card-body py-3">
            <div class="icon rounded-circle shadow-lg d-inline-block">
              <i-feather name="settings" class="fea"></i-feather>
            </div>
            <div class="content mt-3">
              <h5><a href="javascript:void(0)" class="title text-dark">Procesos</a></h5>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-12 col-12 mt-4 pt-2">
        <div class="card explore-feature border-0 rounded text-center bg-white">
          <div class="card-body py-3">
            <div class="icon rounded-circle shadow-lg d-inline-block">
              <i-feather name="user-plus" class="fea"></i-feather>
            </div>
            <div class="content mt-3">
              <h5><a href="javascript:void(0)" class="title text-dark">Cliente</a></h5>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-12 col-12 mt-4 pt-2">
        <div class="card explore-feature border-0 rounded text-center bg-white">
          <div class="card-body py-3">
            <div class="icon rounded-circle shadow-lg d-inline-block">
              <i-feather name="activity" class="fea"></i-feather>
            </div>
            <div class="content mt-3">
              <h5><a href="javascript:void(0)" class="title text-dark">Métricas</a></h5>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!--end container-->

</section>
<!--end section-->

<section class="section bg-light">

  <div class="container mt-0 mt-0">
    <div class="row align-items-center">
      <div class="col-md-6">
        <div class="section-title text-md-left text-center">
          <h4 class="title mb-4">Inquietudes Sobre el Servicio</h4>
          <p class="text-muted mb-0 para-desc">
            En <span class="text-primary font-weight-bold">MDSIS,</span> 
            nuestro grupo de profesionales está siempre dispuesto a atender sus inquietudes 
            y resolverlas en el menor tiempo. <br><br>
            También puede consultar información de los siguientes servicios:
          </p>

          <ul class="list-unstyled text-muted mt-4 mb-0">
            <li class="mb-0">
              <span class="text-primary h5 mr-2">
                <i class="uim uim-check-circle"></i>
              </span>
              <a routerLink="/sofware-factory" class="text-primary h6">
                Fábrica de Software
              </a>
            </li>
            <li class="mb-0">
              <span class="text-primary h5 mr-2">
                <i class="uim uim-check-circle"></i>
              </span>
              <a routerLink="/automation" class="text-primary h6">
                Automatización
              </a>
            </li>
            <li class="mb-0">
              <span class="text-primary h5 mr-2">
                <i class="uim uim-check-circle"></i>
              </span>
              <a routerLink="/business-intelligence" class="text-primary h6">
                Inteligencia de Negocios
              </a>
            </li>
            <li class="mb-0">
              <span class="text-primary h5 mr-2">
                <i class="uim uim-check-circle"></i>
              </span>
              <a routerLink="/digital-transformation" class="text-primary h6">
                Transformación Digital
              </a>
            </li>
          </ul>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="faq-content">
          <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" closeOthers="true">
            
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingone">
                  <h6 class="title mb-0 text-dark">¿Qué metodologías utilizamos? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">
                  Utilizamos todas la herramientas, metodologías, y técnicas posibles en la industria 
                  para garantizar la mayor calidad en el servicio. Nuestros líderes de área 
                  guían y evalúan constantemente el uso de las buenas prácticas según los 
                  marcos de trabajo involucrados en el proyecto.
                  <a routerLink="/aboutus" class="text-primary h6">Más información
                    <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
                  </a>
                </p>
              </ng-template>
            </ngb-panel>
            
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0 text-dark">¿Qué acompañamiento brindamos? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">
                  Acompañamos a los equipos interesados durante toda la idea o proyecto, 
                  desde su etapa inicial hasta su entrega en ambientes productivos. Ofrecemos 
                  asesoría en la definición y validación del proyecto, para garantizar la mejor propuesta 
                  y solución, de acuerdo a las necesidades que tenga la compañía y en pro de las operaciones 
                  del negocio.
                </p>
              </ng-template>
            </ngb-panel>

            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0 text-dark">¿Qué nivel de calidad involucramos?</h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">
                  Implementamos prácticas de integración contínua y despliegue contínuo, acompañado de 
                  evaluación automática de métricas de códificación. Utilizamos planes de pruebas de 
                  calidad con herramientas de automatización, y diseño de pruebas de rendimiento, carga y 
                  estrés, para garantizar los criterios de calidad acordados en todos los componentes de la solución.
                </p>
              </ng-template>
            </ngb-panel>
            
          </ngb-accordion>

        </div>
      </div>
    </div>

    <div class="row mt-2 pt-md-2 justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <a href="contact" class="btn btn-primary mt-4"><i class="mdi mdi-phone"></i> Contáctenos</a>
        </div>
      </div>
    </div>
  
  </div>
  <!--end container-->
</section>
<!--end section-->

<div class="position-relative">
  <div class="shape overflow-hidden text-footer">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top"
  [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
