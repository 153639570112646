<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home"
  style="background: url('assets/images/corporate/1.jpg') center center;">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level title-heading">
          <h1 class="text-white title-dark"> Contáctenos </h1>
          <p class="text-white-50 para-desc mb-0 mx-auto">
            En MDSIS agradecemos su interés en nuestros servicios. 
            Utilice el canal de preferencia para ponerse en contacto con nosotros, 
            y dispondremos del personal necesario para atender su solicitud.
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Contact -->
<section class="section">
  <div class="container">
    <div class="row">
      <div class="col-lg-5 col-md-6  mt-4 pt-2">
        <div class="card shadow rounded border-0">
          <div class="card-body py-5">
            <h4 class="card-title">Póngase en contacto!</h4>
            <div class="custom-form mt-4">
              <div id="message"></div>
              <form novalidate [formGroup]="ContactForm" (ngSubmit)="onFormSubmit()" ngNoForm  name="contact-form" id="contact-form">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group position-relative">
                      <label>Nombre <span class="text-danger">*</span></label>
                      <i-feather name="user" class="fea icon-sm icons"></i-feather>
                      <input name="name" id="name" type="text" class="form-control pl-5" placeholder="First Name :" formControlName="name">
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-md-6">
                    <div class="form-group position-relative">
                      <label>Email <span class="text-danger">*</span></label>
                      <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                      <input name="email" id="email" type="email" class="form-control pl-5" placeholder="Your email :" formControlName="email">
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-md-12">
                    <div class="form-group position-relative">
                      <label>Asunto</label>
                      <i-feather name="book" class="fea icon-sm icons"></i-feather>
                      <input name="subject" id="subject" type="text" class="form-control pl-5" placeholder="Subject" formControlName="subject">
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-md-12">
                    <div class="form-group position-relative">
                      <label>Comentarios</label>
                      <i-feather name="message-circle" class="fea icon-sm icons"></i-feather>
                      <textarea name="comments" id="comments" rows="2" class="form-control pl-5" placeholder="Your Message :" formControlName="comments"></textarea>
                    </div>
                  </div>
                </div>
                <!--end row-->
                <div class="row">
                  <div class="col-sm-12 text-center">
                    <input type="submit" id="submit" name="send" class="submitBnt btn btn-primary btn-block"
                      value="Enviar Mensaje">
                    <div id="simple-msg"></div>
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->

                <!-- Modal Content Start -->
               <ng-template #contentConfirmation let-modal>
                <div class="modal-header">
                  <h5 class="modal-title" id="modal-basic-title">Información Enviada!</h5>
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="bg-white p-3 rounded box-shadow">
                    <p class="text-muted mb-0">
                      Gracias por ponerse en contacto con nosotros.<br><br>
                      La información indicada ha sido enviada y en breve uno de nuestros 
                      agentes se pondrá en contacto con usted para atender su solicitud.
                    </p>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Aceptar</button>
                </div>
              </ng-template>
              <!-- Modal Content End -->

              </form>
              <!--end form-->
            </div>
            <!--end custom-form-->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 pl-md-3 pr-md-3 mt-4 pt-2">
        <div class="card map map-height-two rounded map-gray border-0">
          <div class="card-body p-0">
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3976.480625467921!2d-74.05266248911089!3d4.686218326363668!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f9abfe35ebd7b%3A0x31ec8d5e93d9e246!2zQWMuIDEwMCAjMTktNTQsIFVzYXF1w6luLCBCb2dvdMOh!5e0!3m2!1ses-419!2sco!4v1618251624722!5m2!1ses-419!2sco" 
              style="border:0" class="rounded" allowfullscreen=""></iframe>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row">
      <div class="col-md-4">
        <div class="card contact-detail text-center border-0">
          <div class="card-body p-0">
            <div class="icon">
              <img src="assets/images/icon/bitcoin.svg" class="avatar avatar-small" alt="">
            </div>
            <div class="content mt-3">
              <h4 class="title font-weight-bold">Celular</h4>
              <p class="text-muted">Comuníquese con nuestros profesionales para recibir mayor información de nuestros productos y servicios:</p>
              <a href="tel:+573208051538" class="text-primary">+57 320 8051538</a>&nbsp;&nbsp;
              <a href="tel:+573204089253" class="text-primary">+57 302 2840626</a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="card contact-detail text-center border-0">
          <div class="card-body p-0">
            <div class="icon">
              <img src="assets/images/icon/Email.svg" class="avatar avatar-small" alt="">
            </div>
            <div class="content mt-3">
              <h4 class="title font-weight-bold">Email</h4>
              <p class="text-muted">Envíenos sus comentarios por correo electrónico, y nuestros profesionales se pondrán en contacto con usted:</p>
              <a href="mailto:info@mdsis.com.co" class="text-primary">info@mdsis.com.co</a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="card contact-detail text-center border-0">
          <div class="card-body p-0">
            <div class="icon">
              <img src="assets/images/icon/location.svg" class="avatar avatar-small" alt="">
            </div>
            <div class="content mt-3">
              <h4 class="title font-weight-bold">Ubicación</h4>
              <p class="text-muted">
                Av Calle 100 # 19 - 54 
                <br>Ed Prime Tower - Of 2108
                <br>Bogotá DC, Colombia
              </p>
              <a href="https://goo.gl/maps/R6o6jrBDMAKFD95dA" target="_blank"
                class="video-play-icon h6 text-primary">Ver en Google Map</a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End contact -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top"
  [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
